import React from "react";
import "./Socials.css";
import email from "../Images/email.svg";
import facebook from "../Images/facebook.svg";
import twitter from "../Images/twitter.svg";
import linkedin from "../Images/linkedin.svg";

export default function Socials() {
  return (
    <>
      <div className="reachusatdiv">
        <div className="reachusaticonsdivcontainer">
          <div className="reachusiconsdiv">
            <a href="mailto:info@vncservices.in" className="reachusicons">
              <img className="reachusicons" src={email} />
            </a>
          </div>
          <div className="reachusiconsdiv">
            <img className="reachusicons" src={twitter} />
          </div>
          <div className="reachusiconsdiv">
            <a
              className="reachusicons"
              href="https://m.facebook.com/vncdigitalservices/"
            >
              <img className="reachusicons" src={facebook} />
            </a>
          </div>
          <div className="reachusiconsdiv">
            <a
              className="reachusicons"
              href="https://www.linkedin.com/company/vncdigital/"
            >
              <img className="reachusicons" src={linkedin} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
