import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "../TestComponents/TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function DevOpsConsulting() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Ramp Up/Strategize your DevOps Transformation to Achieve Rapid
            Delivery with Assured Quality
          </h2>

          <h2 className="banner-box-container-2-h2">
            Leverage DevOps Consulting to Get Quality Software &amp; Deliver
            Great Customer Experience (CX)
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>DevOps Consulting</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  Across industry domains, businesses continue to face
                  technology disruptions and need to embrace the new trends of
                  Agile &amp; DevOps methodologies to achieve quality releases
                  and go to market faster. DevOps as a service offering brings
                  in better collaboration between the developers, quality
                  analysts and IT operations teams, delivers quicker feedback
                  loop achieved with continuous integration (CI) and continuous
                  delivery (CD) pipeline. Besides, it brings in improved
                  software quality with faster &amp; quality releases along with
                  reduced project costs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>
            An Overview of Our DevOps Consulting Services: (People, Process,
            Tools &amp; Technologies)
          </h2>
        </div>
        <div className="service-content-type-1">
          <div className="service-content-left-img">
            <img
              src={require("../../Images/devops-consulting-img-1.png")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-right-ul">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Understand the existing solution and technical architecture
              </li>
              <li className="service-differtiater-list-item">
                Establish appropriate metrics to track in DevOps e.g. ROI, Dev
                Metrics
              </li>
              <li className="service-differtiater-list-item">
                Assess current organization’s structure and processes
              </li>
              <li className="service-differtiater-list-item">
                Analyze current automation in place
              </li>
              <li className="service-differtiater-list-item">
                Identify areas of collaboration and sharing
              </li>
              <li className="service-differtiater-list-item">
                Identify areas of improvement
              </li>
              <li className="service-differtiater-list-item">
                Prepare DevOps roadmap and plan
              </li>
              <li className="service-differtiater-list-item">
                Design the to-be model for DevOps implementation
              </li>
              <li className="service-differtiater-list-item">
                Create a comprehensive step-wise plan for implementing DevOps in
                customer IT environment
              </li>
              <li className="service-differtiater-list-item">
                Design a way to integrate infrastructure automation with CI/CD
                tools
              </li>
              <li className="service-differtiater-list-item">
                Build a solution for application monitoring
              </li>
              <li className="service-differtiater-list-item">
                Deliver comprehensive reporting and dashboarding
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Benefits with Our DevOps Consulting Services</h2>
          </div>
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/devops-consulting-images-2.jpg")}
                  style={{ width: "80%" }}
                />
              </div>
            ) : null}
            <div
              className="service-content-right-ul"
              style={{ paddingLeft: 0 }}
            >
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Helps to implement optimized software development processes to
                  optimize your application delivery
                </li>
                <li className="service-differtiater-list-item">
                  Ensures teams to develop better and quality software with
                  assured faster releases
                </li>
                <li className="service-differtiater-list-item">
                  Deliver improved code with better team collaboration which
                  leads to faster time-to-production
                </li>
                <li className="service-differtiater-list-item">
                  Ensures code quality assessment along with code security
                </li>
                <li className="service-differtiater-list-item">
                  Improved business bottom line with well-defined automation in
                  place
                </li>
                <li className="service-differtiater-list-item">
                  Cost optimization with reduced operational costs
                </li>
                <li className="service-differtiater-list-item">
                  Brings you best practices from the industry practices for
                  successful DevOps implementations
                </li>
              </ul>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/devops-consulting-images-2.jpg")}
                  style={{ width: "80%" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default DevOpsConsulting;
