import React, { useState } from "react";
import "../ContactUs.css";
import { send } from "emailjs-com";
import Footer1 from "../Footer1";
import { Link, NavLink, BrowserRouter } from "react-router-dom";

export default function Contact() {
  const [toSend, setToSend] = useState({
    from_name: "",
    phonenumber: "",
    message: "",
    reply_to: "",
    country: "India",
    company: "",
    industry: "InformationTechnology",
    inquirycategory: "Request For Information/Proposal",
  });

  const [termsconditions,settermsconditions] = useState("unchecked");


  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  const onSubmit = (e) => {
    console.log("here in onsubmit");
    
    e.preventDefault();
    if(toSend.from_name == "")  {
      alert("Name cannot be empty");
    }
    else if(toSend.reply_to == "")  {
      alert("Email cannot be empty");
    }
    else if (!isValidEmail(toSend.reply_to)) {
      alert("Email is invalid");
    } 
    else if(toSend.phonenumber == "")  {
      alert("Phone Number cannot be empty");  
    }
    else if(toSend.phonenumber.length<10 || toSend.phonenumber.length>11){
      alert("Enter valid phone number");  
    }
    else if(toSend.message == "")  {
      alert("Message cannot be empty");
    }
    else if(termsconditions=="unchecked") {
      alert("Please accept terms and conditions");
    }
    else {
      send("service_fksdb8g", "template_rfdkbgq", toSend, "TT5FhZyb2Gorr_qpw")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert("Enquiry submitted");
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
    }
    
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const handleTermsConditions = (e) => {
    if(termsconditions == "unchecked")
    settermsconditions("checked");
    else
    settermsconditions("unchecked");
  };

  

  return (
    <div className="contactus-container">
      <img
        src={require("../../Images/contact-us-img-3.jpg")}
        className="contactus-banner-img"
      />
      <div className="navcontact">
        <div className="navcontacttextdiv">
          <p>How Can We Help You?</p>
        </div>
        <div class="navcontactformfields" style={{ flexDirection: "row" }}>
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div class="navcontactformfield">
              <input
                required
                type="text"
                id="name"
                name="from_name"
                class="contact-inputbox1"
                placeholder="Name*"
                value={toSend.from_name}
                onChange={handleChange}
              />
            </div>
            <div class="navcontactformfield">
              <input
                type="text"
                id="Email"
                name="reply_to"
                class="contact-inputbox1"
                placeholder="Email*"
                value={toSend.reply_to}
                onChange={handleChange}
              />
            </div>
            <div class="navcontactformfield">
              <input
                type="number"
                id="Phone"
                class="contact-inputbox1"
                placeholder="PhoneNumber*"
                name="phonenumber"
                value={toSend.phonenumber}
                onChange={handleChange}
              />
            </div>
            <div class="navcontactformfield">
              <input
                type="text"
                id="Company"
                class="contact-inputbox1"
                placeholder="Company"
                name="company"
                value={toSend.company}
                onChange={handleChange}
              />
            </div>
            <div class="navcontactformfield">
              <select
                id="country"
                name="country"
                class="contact-inputbox1"
                value={toSend.country}
                onChange={handleChange}
              >
                <option value="India">India</option>
                <option value="Australia">Australia</option>
                <option value="Newzealand">Newzealand</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Singapore">Singapore</option>
                <option value="Malaysia">Malaysia</option>
                <option value="UK">UK</option>
                <option value="USA">USA</option>
                <option value="UAE">UAE</option>
                <option value="Others">Other</option>
              </select>
            </div>
          </div>
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div class="navcontactformfield">
                <select
                  id="industry"
                  name="industry"
                  class="contact-inputbox1"
                  value={toSend.industry}
                  onChange={handleChange}
                >
                  <option value="InformationTechnology">
                    Information Technology
                  </option>
                  <option value="BankingAndFinance">Banking & Finance</option>
                  <option value="MobileAndWeb">Mobile & Web</option>
                  <option value="SportsAndGames">Sports & Gaming</option>
                  <option value="HealthCareAndHealthTech">Healthcare</option>
                  <option value="EduTech">EduTech</option>
                  <option value="Others">Other</option>
                </select>
              </div>
              <div class="navcontactformfield">
                <select
                  id="inquirycategory"
                  name="inquirycategory"
                  class="contact-inputbox1"
                  value={toSend.inquirycategory}
                  onChange={handleChange}
                >
                  <option value="Information/Proposal">
                    Request Information/Proposal
                  </option>
                  <option value="Alliance/Partnerships">
                    Alliance/Partnerships
                  </option>
                  <option value="Jobs/HR">Jobs/HR</option>
                  <option value="Suppliers">Suppliers</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div class="navcontactformfieldquery">
              <textarea
                type="text"
                id="Query"
                name="message"
                class="contact-inputbox2"
                placeholder="Type your query/message here"
                value={toSend.message}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div class="navcontacttermsconditionsfield">
          <input
            type="checkbox"
            name="termsconditions"
            id="termsconditions"
            value={termsconditions}
            onChange={handleTermsConditions}
          />
          <Link className="termsconditions" to={"./aboutus"}>
          Accept terms and conditions
          </Link>
          
        </div>
        <div class="navcontactsubmit">
          <input
            type="button"
            class="contact-submitinput"
            value="Send"
            onClick={onSubmit}
          />
        </div>
      </div>

      <div className="end-banner" key="footerId" style={{position:'absolute',bottom:'5px'}}>
        <Footer1 />
      </div>
    </div>
  );
}
