import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function DataMigrationTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Seamless Data Migration services ensures Data Accuracy and
            Reliability
          </h2>

          <h2 className="banner-box-container-2-h2">
            Empower Your data Warehouse With Customized Testing Approach
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Data Migration Testing Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  The creation of a data warehouse is a significant undertaking
                  that must produce significant commercial advantages to justify
                  the associated expenses and labour. However, a poor quality
                  assurance strategy causes many ETL programmes to fall short of
                  the desired results. Data should be transferred from various
                  sources to the data warehouse using ETL, or data migration
                  testing, to guarantee that there is no information or data
                  loss, that the transfer rules are strictly followed, and that
                  the validity checks are carried out. If done correctly, ETL
                  Testing should be able to identify any issues with the source
                  data at an early stage. It should also be able to identify any
                  other ambiguities or inconsistencies needed to direct data
                  transformation and integration.
                </p>
                <br />
                <p>
                  To make sure the extraction, transformation, and load (ETL)
                  processes are operating as intended and the outcome is
                  consistent, accurate, and complete, ETL testing should be
                  conducted continuously. We are the top data migration testing
                  business, and we provide our customers with high-quality
                  testing services. Technology teams inside an organisation are
                  frequently asked to enhance internal systems while
                  transitioning to a new technology or platform to satisfy the
                  constantly shifting demands and difficulties of business. Data
                  Testing is essential to the success criteria of the
                  transformation or upgrade initiatives, regardless of where the
                  need for it first arises—from the transformation or adoption
                  of a new data architecture through the migration to a
                  completely new platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default DataMigrationTesting;
