import "../../App.css";
import "./Homepage.css";
import Partners from "../Partners/Partners";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import ProgressBar from "../ProgressBar";
import ScrollspyNav from "react-scrollspy-nav";

import ImageSlider from "../ImageSlider";
import Footer1 from "../Footer1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";
import Scrollspy from "react-scrollspy";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

function DevopServices() {
    const [contentView, setContentView] = useState(["webview"]);
    const [width, height] = useWindowSize();
  const footerRef = useRef(null);
  const homeRef = useRef(null);
  const keyserviceRef = useRef(null);
  const testimonialRef = useRef(null);
  const devopsRef = useRef(null);
  const [hoverImage, setHoverImage] = useState("");

  const [scrollPosition, setScroll] = useState(0);
  const [activeScrollItem, setActiveScrollItem] = useState("home");

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  const handleScroll = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        top: ref.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const setScrollPosition = (scrollPos) => {
    console.log("In scroll position settinggggggggggggg");
    setActiveScrollItem(scrollPos);
  };

  const progressContainerStyle = {
    background: "transparent",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
    height: "5px",
    position: "fixed",
    top: "65px",
    left: 0,
    width: "100vw",
    zIndex: 5000,
  };

  const progressBarStyle = {
    height: "5px",
    background: "green",
    width: scrollPosition,
    opacity: 0.1,
  };

  return (

        <>
           {contentView == "webview" ? ( <div className="keyservices-container" onScroll={setScrollPosition}> <div className="keyservices-row" style={{ width: "80%" }}>
      <div
        className="keyservice"
        style={{
          backgroundImage:
            "url(" +
            require("../../Images/devops-keyservice-img-1.jpg") +
            ")",
          backgroundSize: "100% 100%",
          margin: "2%",
        }}
      >
        <div className="keyservice-ovrlay">
          <h2>DevOps Consulting</h2>
          <p></p>
          <p>
            Ramp Up and strategize devops transformation to achieve rapid
            delivery with assured quality
          </p>
          <p></p>
        </div>
      </div>
      <div
        className="keyservice"
        style={{
          backgroundImage:
            "url(" +
            require("../../Images/devops-keyservice-img-2.jpg") +
            ")",
          backgroundSize: "100% 100%",
          margin: "2%",
        }}
      >
        <div className="keyservice-ovrlay">
          <h2 style={{ color: "white" }}>Devops Transformation</h2>
          <p></p>
          <p>
            DevOps transformation to achieve faster deployments with
            better quality and get faster time to market
          </p>
          <p></p>
        </div>
      </div>
      <div
        className="keyservice"
        style={{
          backgroundImage:
            "url(" +
            require("../../Images/devops-keyservice-img-5.jpg") +
            ")",
          backgroundSize: "100% 100%",
          margin: "2%",
        }}
      >
        <div className="keyservice-ovrlay">
          <h2>Devops Implementation</h2>
          <p></p>
          <p>
            Infrastructure as Code creation and tearing down with
            effective provisioning and automation in place
          </p>
          <p></p>
        </div>
      </div>
    </div>
    <div className="keyservices-row"  style={{ width: "80%" }}>
      <div
        className="keyservice"
        style={{
          backgroundImage:
            "url(" +
            require("../../Images/devops-keyservice-img-3.jpg") +
            ")",
          backgroundSize: "100% 100%",
          margin: "2%",
        }}
      >
        <div className="keyservice-ovrlay">
          <h2>Devops Containerization</h2>
          <p></p>
          <p>
            Containerizing the lightweight, standalone, executables of
            softwares perfect for scaling Up & Down
          </p>
          <p></p>
        </div>
      </div>
      <div
        className="keyservice"
        style={{
          backgroundImage:
            "url(" +
            require("../../Images/devops-keyservice-img-4.jpg") +
            ")",
          backgroundSize: "100% 100%",
          margin: "2%",
        }}
      >
        <div className="keyservice-ovrlay">
          <h2>Devops CI/CD</h2>
          <p></p>
          <p>CI/CD pipeline implementation with continuous testing</p>
          <p></p>
        </div>
      </div>

      <div
        className="keyservice"
        style={{
          backgroundImage:
            "url(" +
            require("../../Images/devops-keyservice-img-6.jpg") +
            ")",
          backgroundSize: "100% 100%",
          margin: "2%",
        }}
      >
        <div className="keyservice-ovrlay">
          <h2>Devops Test Automation</h2>
          <p></p>
          <p>
            Test automation using Agile development tools and CI/CD tools
            providing lifecycle automation
          </p>
          <p></p>
        </div>
      </div></div></div>
         ) : ( <div className="devopsservices-container" onScroll={setScrollPosition}> <div className="keyservices-row" >
         <div
           className="keyservice"
           style={{
             backgroundImage:
               "url(" +
               require("../../Images/devops-keyservice-img-1.jpg") +
               ")",
             backgroundSize: "100% 100%",
             margin: "1%",
           }}
         >
           <div className="keyservice-ovrlay">
             <h2>DevOps Consulting</h2>
             <p></p>
             <p>
               Ramp Up and strategize devops transformation to achieve rapid
               delivery with assured quality
             </p>
             <p></p>
           </div>
         </div>
         <div
           className="keyservice"
           style={{
             backgroundImage:
               "url(" +
               require("../../Images/devops-keyservice-img-2.jpg") +
               ")",
             backgroundSize: "100% 100%",
             margin: "1%",
           }}
         >
           <div className="keyservice-ovrlay">
             <h2 style={{ color: "white" }}>Devops Transformation</h2>
             <p></p>
             <p>
               DevOps transformation to achieve faster deployments with
               better quality and get faster time to market
             </p>
             <p></p>
           </div>
         </div>
         
       </div>
       <div className="keyservices-row" >
        
         
         <div
           className="keyservice"
           style={{
             backgroundImage:
               "url(" +
               require("../../Images/devops-keyservice-img-5.jpg") +
               ")",
             backgroundSize: "100% 100%",
             margin: "1%",
           }}
         >
           <div className="keyservice-ovrlay">
             <h2>Devops Implementation</h2>
             <p></p>
             <p>
               Infrastructure as Code creation and tearing down with
               effective provisioning and automation in place
             </p>
             <p></p>
           </div>
         </div>
         <div
           className="keyservice"
           style={{
             backgroundImage:
               "url(" +
               require("../../Images/devops-keyservice-img-3.jpg") +
               ")",
             backgroundSize: "100% 100%",
             margin: "1%",
           }}
         >
           <div className="keyservice-ovrlay">
             <h2>Devops Containerization</h2>
             <p></p>
             <p>
               Containerizing the lightweight, standalone, executables of
               softwares perfect for scaling Up & Down
             </p>
             <p></p>
           </div>
         </div>
       </div>
       <div className="keyservices-row" >
         
         <div
           className="keyservice"
           style={{
             backgroundImage:
               "url(" +
               require("../../Images/devops-keyservice-img-4.jpg") +
               ")",
             backgroundSize: "100% 100%",
             margin: "1%",
           }}
         >
           <div className="keyservice-ovrlay">
             <h2>Devops CI/CD</h2>
             <p></p>
             <p>CI/CD pipeline implementation with continuous testing</p>
             <p></p>
           </div>
         </div>
   
         <div
           className="keyservice"
           style={{
             backgroundImage:
               "url(" +
               require("../../Images/devops-keyservice-img-6.jpg") +
               ")",
             backgroundSize: "100% 100%",
             margin: "1%",
           }}
         >
           <div className="keyservice-ovrlay">
             <h2>Devops Test Automation</h2>
             <p></p>
             <p>
               Test automation using Agile development tools and CI/CD tools
               providing lifecycle automation
             </p>
             <p></p>
           </div>
         </div></div></div>) }
        </>
  );
}

export default DevopServices;
