import React, { useLayoutEffect, useState, useEffect } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./Navbar.css";
import { navItems, serviceDropdown } from "./NavItems";
import Dropdown from "./Dropdown";
import SideMenu from "./SideMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const FunctionalElements = () => {
  return (
    <li key="functional" className="nav-item">
      <Link className="nav-links" to={"./careers"}>
        Test Automation
      </Link>
    </li>
  );
};

const serviceElements = () => {
  return (
    <li key="functional" className="nav-item">
      <Link className="nav-links" to={"./careers"}>
        Test Automation
      </Link>
    </li>
  );
};

function Navbar() {
  const [dropdown, setDropdown] = useState(false);
  const [functionaldropdown, setFunctionalDropdown] = useState(false);
  const [nonfunctionaldropdown, setNonFunctionalDropdown] = useState(false);
  const [nextgendropdown, setNextgenDropdown] = useState(false);
  const [specializeddropdown, setSpecializedDropdown] = useState(false);
  const [testconsultingdropdown, setTestconsultingDropdown] = useState(false);
  const [devopsdropdown, setDevopsDropdown] = useState(false);
  const [industriesdropdown, setIndustriesDropdown] = useState(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [sidebar, setSidebar] = useState(false);
  const [sideBarElements, setSideBarElements] = useState([]);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();
  const [navElements, setNavElements] = useState([]);
  const [serviceDropDown, setServiceDropDown] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const showSidebar = () => {
    if (sidebar === false) {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  };

  const showDropdown = () => {
    if (dropdown === false) {
      setDropdown(true);
    } else {
      setDropdown(false);
    }
  };

  const onServicePageOpen = () => {
    setDropdown(false);
    setSidebar(false);
  };

  const showFunctionalDropdown = () => {
    if (functionaldropdown === false) {
      setFunctionalDropdown(true);
    } else {
      setFunctionalDropdown(false);
    }
  };

  const showNonFunctionalDropdown = () => {
    if (nonfunctionaldropdown === false) {
      setNonFunctionalDropdown(true);
    } else {
      setNonFunctionalDropdown(false);
    }
  };

  const showNextgenDropdown = () => {
    if (nextgendropdown === false) {
      setNextgenDropdown(true);
    } else {
      setNextgenDropdown(false);
    }
  };

  const showSpecializedDropdown = () => {
    if (specializeddropdown === false) {
      setSpecializedDropdown(true);
    } else {
      setSpecializedDropdown(false);
    }
  };

  const showTestconsultingDropdown = () => {
    if (testconsultingdropdown === false) {
      setTestconsultingDropdown(true);
    } else {
      setTestconsultingDropdown(false);
    }
  };

  const showDevopsDropdown = () => {
    if (devopsdropdown === false) {
      setDevopsDropdown(true);
    } else {
      setDevopsDropdown(false);
    }
  };

  const showServiceDropdown = (serviceType) => {
    let currentServiceDropDown = serviceDropDown;
    console.log("service type" + serviceType);
    for (let i = 0; i <= 5; i++) {
      if (i == serviceType) {
        console.log("current service type" + currentServiceDropDown[i]);
        if (currentServiceDropDown[i] === true) {
          currentServiceDropDown[i] = false;
        } else {
          currentServiceDropDown[i] = true;
        }
      } else {
        currentServiceDropDown[i] = false;
      }
    }
    console.log(currentServiceDropDown);
    setServiceDropDown(currentServiceDropDown);
    console.log(serviceDropDown);
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
      setSidebar(false);
      setDropdown(false);
    } else {
      setContentView("webview");
      setSidebar(false);
      setDropdown(false);
    }
  }, [width]);

  const onMouseEnter = () => {
    if (window.innerWidth < 768) {
      setDropdown(false);
    } else {
      setDropdown(true);
      setSidebar(false);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 768) {
      setDropdown(false);
    } else {
      setDropdown(false);
      setSidebar(false);
    }
  };

  const getFunctionalElements = () => {
    let functionalElements = [];
    console.log("functionalelements" + serviceDropDown[0]);
    if (serviceDropDown[0] === true) {
      functionalElements.push(
        <li
          key="functionalkey"
          className="nav-item"
          onClick={() => showDropdown(true)}
        >
          <Link className="nav-links" to={"./careers"}>
            Test Automation
          </Link>
        </li>
      );
    }

    return functionalElements;
  };

  const getNavbarElements = () => {
    let navBarElements = [];
   // console.log("sidebar value is" + sidebar);
    if (contentView === "mobileview") {
      if (sidebar === true) {
        navBarElements.push(
          <ul className="nav-items-mobile">
            <>
              <li
                key="serviceskey"
                className="nav-item"
                onClick={() => showDropdown(true)}
              >
                <Link className="nav-links" to={"./home"}>
                  Services
                </Link>
              </li>
              {dropdown === true ? (
                <>
                  <li
                    key="functionalkey"
                    className="nav-item"
                    onClick={() => showFunctionalDropdown()}
                  >
                    <Link className="mobile-nav-servive-link" to={"./home"}>
                      Functional
                    </Link>
                  </li>
                  {functionaldropdown === true ? (
                    <>
                      <li
                        key="testautomation"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./testautomation"}
                        >
                          Test Automation
                        </Link>
                      </li>
                      <li
                        key="manualtesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./manualtesting"}
                        >
                          Manual testing
                        </Link>
                      </li>
                      <li
                        key="mobileapptesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./mobileapptesting"}
                        >
                          Mobile App Testing
                        </Link>
                      </li>
                    </>
                  ) : null}
                </>
              ) : null}
              {dropdown === true ? (
                <>
                  <li
                    key="nonfunctional"
                    className="nav-item"
                    onClick={() => showNonFunctionalDropdown(true)}
                  >
                    <Link className="mobile-nav-servive-link" to={"./home"}>
                      Non-Functional
                    </Link>
                  </li>
                  {nonfunctionaldropdown === true ? (
                    <>
                      <li
                        key="performancetesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./performancetesting"}
                        >
                          Performance Testing
                        </Link>
                      </li>
                      <li
                        key="securitytesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./securitytesting"}
                        >
                          Security Testing
                        </Link>
                      </li>
                      <li
                        key="usabilitytesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./usabilitytesting"}
                        >
                          Usability Testing
                        </Link>
                      </li>
                      <li
                        key="accessibilitytesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./accessibilitytesting"}
                        >
                          Accessibility Testing
                        </Link>
                      </li>
                    </>
                  ) : null}
                </>
              ) : null}
              {dropdown === true ? (
                <>
                  <li
                    key="nextgen"
                    className="nav-item"
                    onClick={() => showNextgenDropdown(true)}
                  >
                    <Link className="mobile-nav-servive-link" to={"./home"}>
                      NextGen
                    </Link>
                  </li>
                  {nextgendropdown === true ? (
                    <>
                      <li
                        key="aitesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./aitesting"}
                        >
                          AI Testing
                        </Link>
                      </li>
                      <li
                        key="rpatesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./rpatesting"}
                        >
                          RPA Testing
                        </Link>
                      </li>
                      <li
                        key="iottesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./iottesting"}
                        >
                          IoT Testing
                        </Link>
                      </li>
                    </>
                  ) : null}
                </>
              ) : null}
              {dropdown === true ? (
                <>
                  <li
                    key="specialized"
                    className="nav-item"
                    onClick={() => showSpecializedDropdown(true)}
                  >
                    <Link className="mobile-nav-servive-link" to={"./home"}>
                      Specialized
                    </Link>
                  </li>
                  {specializeddropdown === true ? (
                    <>
                      <li
                        key="apimicroservicestesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./apimicroservicestesting"}
                        >
                          API & Micro Services Testing
                        </Link>
                      </li>
                      <li
                        key="eastesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./eastesting"}
                        >
                          EAS Services Testing
                        </Link>
                      </li>
                      <li
                        key="testdatamanagement"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./testdatamanagement"}
                        >
                          Test Data Management
                        </Link>
                      </li>
                      <li
                        key="datawarehousingtesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./datawarehousingtesting"}
                        >
                          Data Warehousing Testing
                        </Link>
                      </li>
                      <li
                        key="datamigrationtesting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./datamigrationtesting"}
                        >
                          Data Migration Testing
                        </Link>
                      </li>
                    </>
                  ) : null}
                </>
              ) : null}
              {dropdown === true ? (
                <>
                  <li
                    key="testconsulting"
                    className="nav-item"
                    onClick={() => showTestconsultingDropdown(true)}
                  >
                    <Link className="mobile-nav-servive-link" to={"./home"}>
                      Test Consulting
                    </Link>
                  </li>
                  {testconsultingdropdown === true ? (
                    <>
                      <li
                        key="testadvisory"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./testadvisory"}
                        >
                          Test Advisory
                        </Link>
                      </li>
                      <li
                        key="tcoesetup"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./tcoesetup"}
                        >
                          TCoE Setup
                        </Link>
                      </li>
                    </>
                  ) : null}
                </>
              ) : null}
              {dropdown === true ? (
                <>
                  <li
                    key="devops"
                    className="nav-item"
                    onClick={() => showDevopsDropdown(true)}
                  >
                    <Link className="mobile-nav-servive-link" to={"./home"}>
                      Devops
                    </Link>
                  </li>
                  {devopsdropdown === true ? (
                    <>
                      <li
                        key="devopsconsulting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./devops-consulting"}
                        >
                          Devops Consulting
                        </Link>
                      </li>
                      <li
                        key="devopstransformation"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./devops-transformation"}
                        >
                          Devops Transformation
                        </Link>
                      </li>
                      <li
                        key="iacconsulting"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./devops-implementation"}
                        >
                          IAC Consulting
                        </Link>
                      </li>
                      <li
                        key="devopsautomation"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./testautomation-in-devops"}
                        >
                          DevOps Automation
                        </Link>
                      </li>
                      <li
                        key="devopscicd"
                        className="nav-item"
                        onClick={() => onServicePageOpen()}
                      >
                        <Link
                          className="mobile-servive-link"
                          to={"./devops-ci-cd-implementations"}
                        >
                          DevOps CI/CD
                        </Link>
                      </li>
                    </>
                  ) : null}
                </>
              ) : null}
            </>
            <>
              <li
                key="careerskey"
                className="nav-item"
                onClick={() => onServicePageOpen()}
              >
                <div className="mobile-nav-link">
                  <Link className="nav-links" to={"./careers"}>
                    Careers
                  </Link>
                </div>
              </li>
              <li
                key="companykey"
                className="nav-item"
                onClick={() => onServicePageOpen()}
              >
                <div className="mobile-nav-link">
                  <Link className="nav-links" to={"./aboutus"}>
                    Company
                  </Link>
                </div>
              </li>
              <li
                key="contactuskey"
                className="nav-item"
                onClick={() => onServicePageOpen()}
              >
                <div className="mobile-nav-link">
                  <Link className="nav-links" to={"./contact"}>
                    Contact Us
                  </Link>
                </div>
              </li>
            </>
          </ul>
        );
      }
    } else {
      navBarElements.push(
        <ul className="nav-items">
          {navItems.map((item) => {
            if (item.title === "Services") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <Link className="nav-links" to={item.path}>
                    {item.title}
                  </Link>
                  {dropdown && <Dropdown />}
                </li>
              );
            }
            return (
              <li key={item.id} className={item.cName}>
                <Link className="nav-links" to={item.path}>
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      );
    }

    //setNavElements(navBarElements);
    return navBarElements;
  };

  return (
    <div className="navbarcontainer">
      <div className="navbar">
        <div className="nav-area">
          <div className="navbar-logo-box">
            <Link to={"./home"}>
              <img
                className="navbar-logo"
                alt=""
                src={require("../Images/QualiTek_Logo_01-06-2023.png")}
              />
            </Link>
          </div>

          <div className="menu-icon" onClick={showSidebar}>
            {sidebar === false ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
          </div>
          {contentView === "webview" ? getNavbarElements() : null}
        </div>
      </div>
      <div className="mobileview-container">
        {contentView === "mobileview" ? getNavbarElements() : null}
      </div>
    </div>
  );
}

export default Navbar;
