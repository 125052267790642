import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function ManualTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Achieve Functionality Compliant Applications With Our Manual Testing
            Services
          </h2>

          <h2 className="banner-box-container-2-h2">
            Launch Bug Free Applications With Confidence
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h2>Manual Testing Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  Enterprises struggle to achieve the "ideal" level of software
                  development quality as the applications and systems get more
                  sophisticated. For effective functional testing, organisations
                  need strong domain capabilities along with solid testing
                  procedures and technical experience.
                </p>
                <br />
                <p>
                  To guarantee that the product is supplied without flaws in a
                  shorter amount of time, QualiTek delivers a distinctive blend
                  of testing methodologies, tool and technology experience, and
                  domain knowledge. With a foundation of developed test methods,
                  internal accelerators, and familiarity with all of the top
                  functional testing technologies in the market, QualiTek offers
                  end-to-end manual testing services for your functional testing
                  requirements.
                </p>
                <br />

                <p>
                  From developing an effective test plan to providing
                  post-production support, we take full responsibility for the
                  testing activities. We use a user-centric testing strategy to
                  continuously improve the value of our engagements. We
                  guarantee that all business requirements can be traced back to
                  the test cases that were created and ran to provide thorough
                  test coverage. As a reputable manual testing business, we
                  offer thorough reporting with personalised dashboards on a
                  frequent basis to keep you updated on the status of the
                  testing and any errors.
                </p>
              </div>
            </div>
            <div
              className="service-content-right-img"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/Manual-Software-Testing.webp")}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>QualiTek Differentiators</h2>
        </div>
        <div className="service-content-type-1">
          <div className="service-content-left-img">
            <img
              src={require("../../Images/manual_testing_img_1.png")}
              style={{ width: "100%" }}
            />
          </div>

          <div className="service-content-right-ul">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Knowledge of a variety of applications, such as packaged goods,
                desktop, mobile, and web-based ones.
              </li>
              <li className="service-differtiater-list-item">
                Work together with the development teams while supporting a
                variety of development approaches, including Agile, waterfall,
                DevOps, and hybrid models.
              </li>
              <li className="service-differtiater-list-item">
                Early project lifecycle involvement to reduce the cost of
                quality.
              </li>
              <li className="service-differtiater-list-item">
                Specialists with extensive cross-domain testing experience.
              </li>
              <li className="service-differtiater-list-item">
                Bi-Directional traceability is ensured through testing
                requirements procedures.
              </li>
              <li className="service-differtiater-list-item">
                Structure of test cases and method of testing to encourage
                repeatability
              </li>
              <li className="service-differtiater-list-item">
                Business, project, and technology risks are addressed via
                risk-based testing methodology.
              </li>
              <li className="service-differtiater-list-item">
                A formalised flaw procedure documentation to guarantee fault
                repeatability
              </li>
              <li className="service-differtiater-list-item">
                Knowledge of all popular tools for managing the lifetime of an
                application, tests, defects, test data, etc.
              </li>
              <li className="service-differtiater-list-item">
                Project communications that are organised, including daily,
                weekly, and monthly status updates that provide a thorough
                review of the progress of the test execution
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default ManualTesting;
