import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "../TestComponents/TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function DevOpsImplementation() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            IAC Consulting &amp; Implementation – Gateway for Enterprises Faster
            Application Delivery
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>IAC Consulting &amp; Implementation Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  Cloud adoption has become widespread across organizations as a
                  means to reduce the capital expenditures as they adopt IaaS,
                  PaaS or SaaS models using various cloud providers. With the
                  cloud infrastructure becoming ubiquitous, it is important the
                  infrastructure should be consistent, repeatable, idempotent,
                  versioned, easily created and easily taken down. These
                  requirements can only be met by having textual i.e. in the
                  form of code representation.
                </p>
                <br />
                <p>
                  <strong>Infrastruicture As Code</strong>
                  is core to DevOps principles and adopting IAC helps
                  enterprises of all sizes to accelerate software delivery as it
                  makes your system infrastructure consistent, repeatable and
                  versioned.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-left-img">
                <img
                  src={require("../../Images/devops-iac-consulting-img-1.png")}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignSelf: "center",
                  }}
                />
              </div>
            ) : null}
            <div
              className="service-content-right-ul"
              style={{ paddingLeft: 0 }}
            >
              <h2 className="service-ul-header-h2">IAC Consulting</h2>
              <p>
                We offer our consulting services to review your current
                infrastructure provisioning and configuration and draw up a
                detailed plan to automate the provisioning and configuration of
                this infrastructure. These are the typical tasks that do we as
                part of this service:
              </p>
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Educating and evangelizing infrastructure as code
                </li>
                <li className="service-differtiater-list-item">
                  Evaluation of appropriate infrastructure as code tools
                </li>
                <li className="service-differtiater-list-item">
                  Architecture Assessment
                </li>
                <li className="service-differtiater-list-item">
                  Recommendations for logging, monitoring and tracing
                </li>
                <li className="service-differtiater-list-item">
                  Recommend best practices for virtualizing and dockerizing your
                  infrastructure
                </li>
              </ul>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-left-img">
                <img
                  src={require("../../Images/devops-iac-consulting-img-1.png")}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignSelf: "center",
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Provisioning and Configuration Services</h2>
        </div>
        <div className="service-content-type-1">
          <div className="service-content-left-img">
            <img
              src={require("../../Images/devops-iac-consulting-img-2.png")}
              style={{
                width: "100%",
                display: "flex",
                alignSelf: "center",
              }}
            />
          </div>
          <div className="service-content-right-ul">
            <p>
              Herein, we offer services to automate the configuration and
              provisioning of your public cloud infrastructure using tools like
              ansible, Cloud Formation, terraform, packer etc. These are the
              typical tasks that we do as part of this service:
            </p>
            <br />
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Infrastructure provisioning using tools like Ansible, Terraform,
                CloudFormation and CLI tools.
              </li>
              <li className="service-differtiater-list-item">
                Configuration of infrastructure using ansible, packer etc
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-content-type-1">
          {contentView == "mobileview" ? (
            <div className="service-content-left-img">
              <img
                src={require("../../Images/devops-iac-consulting-img-3.png")}
                style={{
                  width: "60%",
                }}
              />
            </div>
          ) : null}
          <div className="service-content-right-ul" style={{ paddingLeft: 0 }}>
            <h2 className="service-ul-header-h2">
              Benefits With IAC Implementation
            </h2>

            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Delivers faster infrastructure creation and tearing down with
                effective provisioning and automation in place
              </li>
              <li className="service-differtiater-list-item">
                Ensures overall cost reduction
              </li>
              <li className="service-differtiater-list-item">
                Ensures reduction of CAPEX and OPEX
              </li>
              <li className="service-differtiater-list-item">
                Accelerates time-to-deliver from infrastructure perspective
              </li>
              <li className="service-differtiater-list-item">
                Enables versioning control and thus closer monitoring of
                infrastructure
              </li>
              <li className="service-differtiater-list-item">
                Faster fault restoration
              </li>
              <li className="service-differtiater-list-item">
                Eliminates configuration drift from your deployments
              </li>
              <li className="service-differtiater-list-item">
                Eliminates any snowflake environments
              </li>
            </ul>
          </div>
          {contentView == "webview" ? (
            <div className="service-content-left-img">
              <img
                src={require("../../Images/devops-iac-consulting-img-3.png")}
                style={{
                  width: "60%",
                }}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-content-type-1">
          <div className="service-content-left-img">
            <img
              src={require("../../Images/DevOps-Transformation-img-1.png")}
              style={{
                width: "70%",
              }}
            />
          </div>
          <div className="service-content-right-ul">
            <h2 className="service-ul-header-h2">Why Choose Us</h2>

            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Your IT becomes more agile and responsive
              </li>
              <li className="service-differtiater-list-item">
                Helps you with quicker recovery from any eventual critical error
                or failures
              </li>
              <li className="service-differtiater-list-item">
                Automates your entire IT operations to deliver faster releases
                and faster time-to-market
              </li>
              <li className="service-differtiater-list-item">
                Ensures quicker ROI as software is rolled out faster
              </li>
              <li className="service-differtiater-list-item">
                Disaster recovery becomes easy with better data management
                across the hybrid cloud environment
              </li>
            </ul>
          </div>
        </div>
        <p style={{ width: "100%" }}>
          Connect with our IAC and DevOps experts today to get a complete
          understanding of IAC infrastructure implementation. Our DevOps experts
          will guide you through the entire process implementation
        </p>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Our Expertise In Tools</h2>
          </div>

          <div
            className="service-content-right-img"
            style={{
              position: "relative",
              width: "80%",
              height: "auto",
            }}
          >
            <img
              src={require("../../Images/devops-implementation-tools.png")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default DevOpsImplementation;
