import React from "react";
import "./Careers.css";
import Footer1 from "../Footer1";

function Careers() {
  return (
    <div>
      <div className="careers-container"></div>

      <div className="careers-box">
        <div className="careers-box-banner">
          <h1 className="careers-box-h1">
            Here at QualiTek, we are proud to be an <br />
            organization that takes pride in its workforce
          </h1>
        </div>

        <p className="careers-box-p">
          At every step, we have been built by individuals who have gone beyond
          the limits of personal goals and leaped across boundaries. It is the
          freedom provided to every individual at QualiTek to learn, grow and
          create a career path that makes us an organization of opportunities
          beyond boundaries. Beyond boundaries of region, age, gender, ability
          and routine. We invite you to be a part of this experience.
        </p>
      </div>
      <div className="careers-service-box">
        <p>Find out the open positions and drop your Resume to us </p>

        <p>
          at <strong> info@qualitek.ai</strong>
        </p>
      </div>

      <div className="end-banner" key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default Careers;
