import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "../TestComponents/TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function DevOpsCICD() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Implementing DevOps CI/CD will accelerate delivery and improve
            software quality.
          </h2>

          <h2 className="banner-box-container-2-h2">
            Get Shorter Delivery Cycles and Quality Releases by Adopting DevOps
            CI/CD Best Practices
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>DevOps CI/CD Implementation</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  The Continuous Integration (CI) philosophy is to constantly
                  check in new code to a central repository and make modest code
                  changes to ensure that you are adding new features (or fixing
                  bugs) without damaging any already-existing functionality.
                  Automated tests should be used periodically to verify that the
                  functionality that is currently in place is not broken.
                  Therefore, CI can only be usefully implemented when there is
                  sufficient automated testing.
                </p>
                <br />
                <p>
                  Where CI ends, Continuous Delivery (CD) begins. The automatic
                  delivery of applications to the designated infrastructure
                  settings is a part of the CD process. The CD process makes
                  sure that there is an automated means to send code changes to
                  different environments. DevOps teams work across several
                  environments, including development, testing, and production
                  environments.
                </p>
                <br />
                <p>
                  In order to help development teams produce code changes more
                  frequently and reliably, CI and CD approaches typically embody
                  a particular culture, a set of DevOps operational principles,
                  and a collection of best automation practises. Because
                  deployments are automated, the procedure used to verify code
                  quality and security is known as a "CI/CD pipeline."
                </p>
                <br />
                <p>
                  Continuous testing (CT) is a requirement of CI/CD procedures
                  because delivering high-quality software is every project's
                  primary goal. Using test automation tools with regression,
                  performance, security, and other testing methodologies that
                  are conducted within the CI/CD pipeline, this continuous
                  testing process is implemented.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Identify Right Tools and Technologies</h2>
        </div>

        {contentView == "mobileview" ? (
          <div className="service-content-type-1">
            <div className="service-content-right-img">
              <img
                src={require("../../Images/devops_ci_cd_img_1.png")}
                style={{ width: "100%" }}
              />
            </div>
            <div
              className="service-content-right-ul"
              style={{ paddingLeft: 0 }}
            >
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  available current tools and technologies assesment
                </li>
                <li className="service-differtiater-list-item">
                  investigation of the feasibility of the tool
                </li>
                <li className="service-differtiater-list-item">
                  Select the most suitable tool to ensure successful process
                  implementation.
                </li>
                <li className="service-differtiater-list-item">
                  Advice the most suitable tool for the job.
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="service-content-type-1">
            <div
              className="service-content-right-ul"
              style={{ paddingLeft: 0 }}
            >
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  available current tools and technologies assesment
                </li>
                <li className="service-differtiater-list-item">
                  investigation of the feasibility of the tool
                </li>
                <li className="service-differtiater-list-item">
                  Select the most suitable tool to ensure successful process
                  implementation.
                </li>
                <li className="service-differtiater-list-item">
                  Advice the most suitable tool for the job.
                </li>
              </ul>
            </div>
            <div className="service-content-right-img">
              <img
                src={require("../../Images/devops_ci_cd_img_1.png")}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        )}
      </div>

      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Create and Enhance CI/CD Pipelines</h2>
          </div>
          <div className="service-content-type-1">
            <div className="service-content-left-img">
              <img
                src={require("../../Images/devops_ci_cd_img_2.png")}
                style={{ width: "100%" }}
              />
            </div>
            <div className="service-content-right-ul">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Automation guarantees quicker and higher-quality releases
                </li>
                <li className="service-differtiater-list-item">
                  breaks down team silos and enhances team cooperation, which
                  boosts code quality and produces faster releases.
                </li>
                <li className="service-differtiater-list-item">
                  reduces the time it takes new features to market
                </li>
                <li className="service-differtiater-list-item">
                  Automation cuts expenses and requires fewer manual
                  interventions.
                </li>
                <li className="service-differtiater-list-item">
                  Automation reduces costs and manual interventions
                </li>
                <li className="service-differtiater-list-item">
                  streamlines team communications with a quick feedback loop
                </li>
                <li className="service-differtiater-list-item">
                  provides complete performance stats for applications
                </li>
                <li className="service-differtiater-list-item">
                  guarantees code quality and security
                </li>
                <li className="service-differtiater-list-item">
                  increases consumer satisfaction
                </li>
                <li className="service-differtiater-list-item">
                  Utilize our extensive industry knowledge of DevOps tools and
                  creating unique automation frameworks to reap the greatest
                  benefits
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Train Client Teams on CI/CD Pipelines</h2>
        </div>
        {contentView == "mobileview" ? (
          <div className="service-content-type-1">
            <div className="service-content-right-img">
              <img
                src={require("../../Images/devops_ci_cd_img_3.png")}
                style={{ width: "100%" }}
              />
            </div>
            <div className="service-content-right-ul">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Ensure the client team handles the CI/CD pipeline creation
                </li>
                <li className="service-differtiater-list-item">
                  Enable effective training about the usage of various tools and
                  technologies
                </li>
                <li className="service-differtiater-list-item">
                  Ensure the pipelines run successfully
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="service-content-type-1">
            <div className="service-content-right-ul">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Ensure the client team handles the CI/CD pipeline creation
                </li>
                <li className="service-differtiater-list-item">
                  Enable effective training about the usage of various tools and
                  technologies
                </li>
                <li className="service-differtiater-list-item">
                  Ensure the pipelines run successfully
                </li>
              </ul>
            </div>
            <div className="service-content-right-img">
              <img
                src={require("../../Images/devops_ci_cd_img_3.png")}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>
              Why should you choose us for your DevOps CI/CD implementations?
            </h2>
          </div>

          <div className="service-content-type-1">
            <div
              className="service-content-left-img"
              style={{ position: "relative", marginTop: "100px" }}
            >
              <img
                src={require("../../Images/devops_ci_cd_img_4.png")}
                style={{ width: "100%" }}
              />
            </div>
            <div className="service-content-right-ul">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  10+ years of experience in testing services
                </li>
                <li className="service-differtiater-list-item">
                  Dedicated DevOps expert team ensure scalable, secure and
                  reliable testing
                </li>
                <li className="service-differtiater-list-item">
                  Ensure automation with deployments and rollbacks performed in
                  a click with low risks and high productivity
                </li>
                <li className="service-differtiater-list-item">
                  Deliver hassle free project management with flexible
                  engagement models
                </li>
                <li className="service-differtiater-list-item">
                  Recommend the best DevOps toolchain that best suits your
                  project model
                </li>
                <li className="service-differtiater-list-item">
                  Focus closely on user security and protection
                </li>
                <li className="service-differtiater-list-item">
                  Expertise on industry leading test automation (e.g. UFT,
                  Selenium, TestComplete, Coded UI, etc.), Agile development
                  tools (e.g. Rally, Scrumdo) and CI/CD tools (e.g. Jenkins,
                  Chef, Puppet, TFS, Hudson, Go, Bamboo, etc.) providing
                  lifecycle automation
                </li>
                <li className="service-differtiater-list-item">
                  Certified automation experts with expertise in implementing
                  advanced automation frameworks
                </li>
                <li className="service-differtiater-list-item">
                  Standardized processes, templates and toolkits for Agile and
                  DevOps QA
                </li>
                <li className="service-differtiater-list-item">
                  Global team of QA professionals with a capability to scale up
                  at both onsite and offshore
                </li>
                <li className="service-differtiater-list-item">
                  Enable continuous support and training for client teams
                </li>
                <li className="service-differtiater-list-item">
                  Version control system roll out with appropriate branching
                  strategy
                </li>
                <li className="service-differtiater-list-item">
                  Continuous Integration- Compile, validate, code review, unit
                  testing and integration testing
                </li>
                <li className="service-differtiater-list-item">
                  Continuous Delivery- Deploy the build application to test
                  servers, and perform UAT
                </li>
                <li className="service-differtiater-list-item">
                  Continuous Deployment- Deploy the tested application on the
                  production server for release
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default DevOpsCICD;
