import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function TCoESetup() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2 className="banner-box-container-2-h2">
            Increase the quality of your Quality with our TCOE Setup Services
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>TCoE Setup Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  The software testing industry has long understood the
                  importance of a Testing Center of Excellence (CoE). The
                  Testing Center of Excellence (TCoE) offers specialised testing
                  services, knowledge, procedures, and technologies.
                  Organizations can standardise and enhance their testing
                  procedures, as well as increase the efficacy and efficiency of
                  their testing teams, with the aid of a CoE testing centre.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Next Gen QA Organization</h2>
          </div>

          <div
            className="service-content-desc"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <div
              className="service-content-right-img"
              style={{ width: "40%", justifyContent: "center" }}
            >
              <img
                src={require("../../Images/tcoe-testing-img-1.png")}
                style={{ width: "80%" }}
              />
            </div>
          </div>
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                The TCoE offers various organisational advantages, such as
                increased efficiency, lower costs, and better quality. Your Test
                Center of Excellence will be established from start with the aid
                of QualiTek TCoE services, and you will collaborate closely on
                developing the TCoE's overarching vision, roadmap, and
                structure. QualiTek offers unrivalled value as a QA partner
                thanks to its experience assisting multinational corporations
                with setting up and maintaining their TCoEs. Within the
                boundaries of TCoE, QualiTek works as an extended QA arm with
                your development team and establishes the necessary technical
                testing CoEs on the ground. QualiTek gives you the flexibility
                you need to satisfy business demands at an affordable price
                point with a pool of competent testing resources both on-site
                and offshore.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default TCoESetup;
