import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "../TestComponents/TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function DevOpsTransformation() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Embrace Next-gen Enterprise DevOps Transformation to Achieve Faster
            Deployments with Better Quality and get Faster Time to Market
          </h2>

          <h2 className="banner-box-container-2-h2">{""}</h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Enterprise DevOps Transformation Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  The global Software market is evolving at an incredible pace
                  and enterprises must embrace enterprise DevOps transformation
                  to remain relevant . Today’s enterprises need faster and
                  quality releases to go to market faster and need greater
                  agility to withstand the competition.
                </p>
                <br />
                <p>
                  Effective and strategic DevOps implementations need to be
                  integrated with a set of automation tools which increases team
                  agility, ensures better collaboration between teams, delivers
                  faster deployments, lowers operational costs, and gets you
                  faster time-to-market along with quicker ROI.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div
            className="service-content-right-ul"
            style={{ width: "100%", paddingLeft: 0, alignSelf: "flex-start" }}
          >
            <h2 className="service-ul-header-h2">
              Our Enterprise DevOps Transformation Services include:
            </h2>
            <ul
              className="service-differtiater-list-body"
              style={{
                width: "100%",
                alignSelf: "flex-start",
              }}
            >
              <li className="service-differtiater-list-item">
                DevOps Consulting
              </li>
              <li className="service-differtiater-list-item">
                IAC Consulting and Implementation
              </li>
              <li className="service-differtiater-list-item">
                DevOps CI/CD Implementation
              </li>
              <li className="service-differtiater-list-item">
                Test Automation in DevOps
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-content-left-desc" style={{ width: "100%" }}>
            <h2 className="service-ul-header-h2">
              Bringing in business agility with DevOps transformation
            </h2>
            <p>
              With today’s complex and integrated applications across
              industries, there is a need to modernize and adopt a digital way
              of alignment to be brought in across people, process, tools and
              methodologies. Acceleration of software delivery is all that
              matters today to achieve business goals.
            </p>
            <br />
            <p>
              Our enterprise DevOps assessments bring in deep insights as we
              understand the key friction areas and get the best DevOps
              practices to ensure great collaboration between teams with assured
              faster and quality releases and get you faster time-to-market.
            </p>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <h2 className="service-ul-header-h2">
                  DevOps Enterprise Transformations
                </h2>
                <p>
                  Lack of proper collaboration between developers and operations
                  teamsEnsures better collaboration between the teams70-80%
                  Project managers admit facing challenges with delivering
                  projects on time and on admitted costsWith continuous
                  integration, continuous testing, continuous delivery is
                  achieved with streamlined DevOps implementationsCode
                  deployments take lot of timeVery frequent code
                  deploymentsProject success can be unpredictableHigher project
                  success (rates) due to continuous collaboration and regular
                  feedbackChallenges with product launch and qualityEffective
                  improvement in product quality, security, visibility and
                  customer satisfaction
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Deployment based benefits</h2>
        </div>
        <div className="service-content-type-1">
          <div className="service-content-left-img">
            <img
              src={require("../../Images/DevOps-Transformation-img-1.png")}
              style={{
                width: "60%",
              }}
            />
          </div>
          <div className="service-content-right-ul">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Delivers faster code deployments
              </li>
              <li className="service-differtiater-list-item">
                Ensures shorter development cycles
              </li>
              <li className="service-differtiater-list-item">
                Brings in faster solution releases
              </li>
              <li className="service-differtiater-list-item">
                Reduces cycle time by 30%
              </li>
              <li className="service-differtiater-list-item">
                Gets in shorter feedbacks loop with customers
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Code wise benefits</h2>
          </div>
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/DevOps-Transformation-img-2.png")}
                  style={{ width: "80%" }}
                />
              </div>
            ) : null}
            <div
              className="service-content-right-ul"
              style={{ paddingLeft: 0 }}
            >
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Improves time to market enormously
                </li>
                <li className="service-differtiater-list-item">
                  Reduces OPEX support cost associated with legacy applications
                </li>
                <li className="service-differtiater-list-item">
                  Increases ROI
                </li>
                <li className="service-differtiater-list-item">
                  Reduces operational costs
                </li>
                <li className="service-differtiater-list-item">
                  Reduces risk associated with deployments and upgrades
                </li>
                <li className="service-differtiater-list-item">
                  Reduces delivery costs
                </li>
              </ul>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/DevOps-Transformation-img-2.png")}
                  style={{ width: "80%" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Our DevOps Stack</h2>
        </div>

        <div
          className="service-content-right-img"
          style={{
            position: "relative",
            width: "80%",
            height: "auto",
          }}
        >
          <img
            src={require("../../Images/DevOps-Transformation-img-3.png")}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default DevOpsTransformation;
