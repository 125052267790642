import "../../App.css";
import "./Homepage.css";
import Partners from "../Partners/Partners";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import ProgressBar from "../ProgressBar";
import ScrollspyNav from "react-scrollspy-nav";

import ImageSlider from "../ImageSlider";
import Footer1 from "../Footer1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";
import Scrollspy from "react-scrollspy";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

function KeyServices() {
    const [contentView, setContentView] = useState(["webview"]);
    const [width, height] = useWindowSize();
  const footerRef = useRef(null);
  const homeRef = useRef(null);
  const keyserviceRef = useRef(null);
  const testimonialRef = useRef(null);
  const devopsRef = useRef(null);
  const [hoverImage, setHoverImage] = useState("");

  const [scrollPosition, setScroll] = useState(0);
  const [activeScrollItem, setActiveScrollItem] = useState("home");

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  const handleScroll = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        top: ref.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const setScrollPosition = (scrollPos) => {
    console.log("In scroll position settinggggggggggggg");
    setActiveScrollItem(scrollPos);
  };

  const progressContainerStyle = {
    background: "transparent",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
    height: "5px",
    position: "fixed",
    top: "65px",
    left: 0,
    width: "100vw",
    zIndex: 5000,
  };

  const progressBarStyle = {
    height: "5px",
    background: "green",
    width: scrollPosition,
    opacity: 0.1,
  };

  return (

   

        <div className="keyservices-container" onScroll={setScrollPosition}>
           {contentView == "webview" ? ( <>
          <div className="keyservices-row">
            <div
              className="keyservice"
              id="keyservice-01"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-8.webp") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-8.webp") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2 className="service-name">Manual Testing</h2>
                <p></p>
                <p>
                  Manual functional testing of applications to ensure all
                  features work as expected.
                </p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              id="keyservice-02"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-3.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-3.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Test Automation</h2>
                <p></p>
                <p>
                  Automation of regression test suites to achieve lower testing
                  costs and faster time to market.
                </p>
                <p></p>
              </div>
            </div>

            <div
              className="keyservice"
              id="keyservice-03"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-7.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-7.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Performance Testing</h2>
                <p></p>
                <p>
                  Testing for Speed, stability and scalability to ensure the
                  applications performs well under peak load.
                </p>
                <p></p>
              </div>
            </div>
          </div>

          <div className="keyservices-row">
            <div
              className="keyservice"
              id="keyservice-04"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-5.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-5.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Security Testing</h2>
                <p></p>
                <p>
                  Testing to ensure the applications are secure against any
                  vulnerabilities.
                </p>
                <p></p>
              </div>
            </div>

            <div
              className="keyservice"
              id="keyservice-05"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-10.webp") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-10.webp") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>MobileApp Testing</h2>
                <p></p>
                <p>Android IOS and all other Platforms, Hybrid Apps Testing</p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              id="keyservice-06"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-2.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-2.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2 style={{ color: "white" }}>Digital Testing</h2>
                <p></p>
                <p>
                  E2E functional & non-functional testing of digital apps
                  including social, mobile, analytics & cloud.
                </p>
                <p></p>
              </div>
            </div>
          </div>
          <div className="keyservices-row">
            <div
              className="keyservice"
              id="keyservice-07"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-9.png") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-9.png") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              {" "}
              <div className="keyservice-ovrlay">
                <h2 style={{ color: "white" }}>DW/Analytics</h2>
                <p></p>
                <p>Testing for end to end DW and Analytics value chain.</p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              id="keyservice-08"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-1.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-1.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>DevOps &amp; Agile Automation</h2>
                <p></p>
                <p>Testing aligned to DevOps and Agile methodologies.</p>
                <p></p>
              </div>
            </div>

            <div
              className="keyservice"
              id="keyservice-09"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-4.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-4.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Test Advisory Service</h2>
                <p></p>
                <p>
                  Review of entire Testing organization including Processes,
                  People and Tools &amp; Technologies.
                </p>
                <p></p>
              </div>
            </div>
          </div> </>) : ( <><div className="keyservices-row">
            <div
              className="keyservice"
              id="keyservice-01"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-8.webp") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-8.webp") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2 className="service-name">Manual Testing</h2>
                <p></p>
                <p>
                  Manual functional testing of applications to ensure all
                  features work as expected.
                </p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              id="keyservice-02"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-3.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-3.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Test Automation</h2>
                <p></p>
                <p>
                  Automation of regression test suites to achieve lower testing
                  costs and faster time to market.
                </p>
                <p></p>
              </div>
            </div>

            </div>

            <div className="keyservices-row">
            <div
              className="keyservice"
              id="keyservice-03"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-7.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-7.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Performance Testing</h2>
                <p></p>
                <p>
                  Testing for Speed, stability and scalability to ensure the
                  applications performs well under peak load.
                </p>
                <p></p>
              </div>
            </div>

            <div
              className="keyservice"
              id="keyservice-04"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-5.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-5.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Security Testing</h2>
                <p></p>
                <p>
                  Testing to ensure the applications are secure against any
                  vulnerabilities.
                </p>
                <p></p>
              </div>
            </div>

            
          </div>

          <div className="keyservices-row">
           

            <div
              className="keyservice"
              id="keyservice-05"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-10.webp") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-10.webp") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>MobileApp Testing</h2>
                <p></p>
                <p>Android IOS and all other Platforms, Hybrid Apps Testing</p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              id="keyservice-06"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-2.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-2.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2 style={{ color: "white" }}>Digital Testing</h2>
                <p></p>
                <p>
                  E2E functional & non-functional testing of digital apps
                  including social, mobile, analytics & cloud.
                </p>
                <p></p>
              </div>
            </div>
          </div>
          <div className="keyservices-row">
            <div
              className="keyservice"
              id="keyservice-07"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-9.png") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-9.png") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              {" "}
              <div className="keyservice-ovrlay">
                <h2 style={{ color: "white" }}>DW/Analytics</h2>
                <p></p>
                <p>Testing for end to end DW and Analytics value chain.</p>
                <p></p>
              </div>
            </div>
            <div
              className="keyservice"
              id="keyservice-08"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-1.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-1.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>DevOps &amp; Agile Automation</h2>
                <p></p>
                <p>Testing aligned to DevOps and Agile methodologies.</p>
                <p></p>
              </div>
            </div> </div>
            <div className="keyservices-row">
            <div
              className="keyservice"
              id="keyservice-09"
              style={{
                backgroundImage:
                  "url(" + require("../../Images/keyarea-img-4.jpg") + ")",
                backgroundSize: "100% 100%",
              }}
              onMouseEnter={() =>
                setHoverImage(
                  "url(" + require("../../Images/keyarea-img-4.jpg") + ")"
                )
              }
              onMouseLeave={() => setHoverImage("")}
            >
              <div className="keyservice-ovrlay">
                <h2>Test Advisory Service</h2>
                <p></p>
                <p>
                  Review of entire Testing organization including Processes,
                  People and Tools &amp; Technologies.
                </p>
                <p></p>
              </div>
            </div>
          </div> </>) }
        </div>
  );
}

export default KeyServices;
