import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function RPATesting() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Achieve Robotic Functionality Compliant Applications With Our
            Testing Services
          </h2>

          <h2 className="banner-box-container-2-h2">
            Launch Bug Free Robotic Applications With Coincidence
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>RPA Testing Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  One of the finest technology for today's organisations is
                  robotic process automation (RPA), which enables designing a
                  robot to interact with human activities to efficiently carry
                  out recurring business procedures. Evidently, this technology
                  aids businesses in increasing operational efficiency by using
                  a software bot to automate routine business activities and
                  remove the need for human participation.
                </p>
                <br />
                <p>
                  The global RPA market size has been evaluated at $1.40 B in
                  2019 and is predicted to reach $11 B by 2027. Most of the CXOs
                  across the globe are using this technology for cutting
                  expenses and for enhancing their business efficiency employing
                  these RPA bots. Additionally, enterprises in a variety of
                  industries, including banking, healthcare, and insurance, are
                  enjoying enormous benefits from RPA implementation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-sub-header-h2">
            <h2>Performance Testing of RPA Bots</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  RPA bots can be used in a number of business sectors,
                  including the insurance industry for the processing of
                  insurance claims and clearing insurance premiums, among
                  others.
                </p>
                <p>
                  - The telecom industry, including for service order
                  management.
                </p>
                <p>
                  - The travel sector for the purchase of passenger tickets,
                  thorough accounting, etc.
                </p>
                <p>
                  - The retail industry for tracking consumer orders,
                  calculating sales, etc
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-sub-header-h2">
            <h2>Functional Testing of RPA Bots</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  RPA robots should undoubtedly undergo thorough testing to make
                  sure they carry out their duties without error. Enabling
                  end-to-end testing is crucial since these RPA implementations
                  frequently include heterogeneous programmes, components, and
                  technologies that operate on different operating systems. With
                  internal RPA certified testing specialists, Qualitek has
                  experience enabling RPA testing services. Our teams use both
                  functional and non-functional testing as part of a clearly
                  defined RPA testing process to evaluate robots. From the
                  production of test data to the bot's activation, we follow an
                  appropriate automation testing strategy to make sure these
                  bots function in accordance with the business process
                  validations for which they are utilised. To verify the
                  efficient operation of the bots, we also conduct testing using
                  both open source and paid automation testing technologies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-img">
                <img src={require("../../Images/rpa-testing-img-1.png")} />
              </div>
            ) : null}
            <div
              className="service-content-right-ul"
              style={{ paddingLeft: 0 }}
            >
              <h2 className="service-ul-header-h2">
                Some of the industry segments RPA bots can be leveraged are:
              </h2>
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Insurance sector for processing of claims, insurance premium
                  clearances, etc.
                </li>
                <li className="service-differtiater-list-item">
                  Telecom sector for service order management and more
                </li>
                <li className="service-differtiater-list-item">
                  Travel industry for passenger ticket booking, detailed
                  accounting, etc.
                </li>
                <li className="service-differtiater-list-item">
                  Retail sector for sales calculations, customer order tracking,
                  etc.
                </li>
              </ul>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/rpa-testing-img-1.png")}
                  style={{ width: "70%" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  Evidently, for these RPA robots to perform seamlessly, they
                  should be tested effectively to ensure they are seamless in
                  performing their tasks. Moreover, as these RPA implementations
                  usually consist of heterogeneous applications, components, and
                  technologies that run on multiple operating systems, hence
                  enabling end-to-end testing is essential.
                </p>
                <br />
                <p>
                  QualiTek has expertise in enabling RPA testing services with
                  in-house RPA certified testing specialists. Our teams follow a
                  well-articulated RPA testing process to test robots leveraging
                  both Functional and Non-Functional testing. We follow a proper
                  automation testing strategy starting from test data creation
                  to triggering the bot and ensure these bots work articulated
                  as per the business process validations for which they are
                  leveraged. We also perform testing leveraging open source and
                  commercial automation testing tools to ensure bots effective
                  performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Our RPA Testing Process</h2>
        </div>
        <div className="service-content-type-1">
          {contentView == "mobileview" ? (
            <div className="service-content-right-ul-img">
              <img src={require("../../Images/rpa-testing-img-2.png")} />
            </div>
          ) : null}
          <div className="service-content-right-ul" style={{ paddingLeft: 0 }}>
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Analyze and understand the requirements in detail
              </li>
              <li className="service-differtiater-list-item">
                Identify required automated testing requirements
              </li>
              <li className="service-differtiater-list-item">
                Prepare test data
              </li>
              <li className="service-differtiater-list-item">UI testing</li>
              <li className="service-differtiater-list-item">
                Create test cases and scripts
              </li>
              <li className="service-differtiater-list-item">
                Execute test cases by enabling automation scripting by
                leveraging automation tools
              </li>
              <li className="service-differtiater-list-item">
                Validate data as per the tests
              </li>
              <li className="service-differtiater-list-item">
                Analyze and deliver reports
              </li>
            </ul>
          </div>
          {contentView == "webview" ? (
            <div className="service-content-right-ul-img">
              <img src={require("../../Images/rpa-testing-img-2.png")} />
            </div>
          ) : null}
        </div>
      </div>

      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>QualiTek Differentiators</h2>
          </div>
          <div className="service-content-type-1">
            <div className="service-content-left-img">
              <img
                src={require("../../Images/rpa-testing-img-1.jpg")}
                style={{ width: "100%" }}
              />
            </div>
            <div className="service-content-right-ul">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  A team of certified RPA testing professionals
                </li>
                <li className="service-differtiater-list-item">
                  Experience in testing bots using the best methods and
                  processes
                </li>
                <li className="service-differtiater-list-item">
                  Assures quicker ROI with optimized bot performance
                </li>
                <li className="service-differtiater-list-item">
                  Ensures faster test creation & test execution
                </li>
                <li className="service-differtiater-list-item">
                  Ensures lesser maintenance effort with overall 30% cost
                  savings
                </li>
                <li className="service-differtiater-list-item">
                  End-to-end testing of processes and BOTs
                </li>
                <li className="service-differtiater-list-item">
                  As tasks are automated, the process involves minimal workforce
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default RPATesting;
