import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function EASTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Achieve Functionality Compliant Applications With Our Manual Testing
            Services
          </h2>

          <h2 className="banner-box-container-2-h2">
            Launch Bug Free Applications With Confidence
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Enterprise Applications Quality Assurance Services (EAS)</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  IT efforts are moving more quickly, innovatively, and agilely
                  thanks to digital transformation. The back office and front
                  end applications are primarily integrated via business process
                  management (BPM), customer relationship management (CRM), and
                  enterprise resource planning (ERP) systems. Additionally,
                  these systems efficiently link important business activities
                  amongst different corporate departments including finance,
                  human resources, supply chain, and sales & marketing.
                </p>
                <br />
                <p>
                  The user experience is changing thanks to the next-generation
                  CRM systems like Salesforce and post-modern ERP software like
                  SAP S/4 HANA, Oracle Cloud, Microsoft Dynamics, and Oracle.
                  These apps provide promising technological and functional
                  possibilities. Pega and other BPM tools support the adoption
                  of modern business models that innovate, automate, and
                  transform organisations quickly and at scale.
                </p>
                <br />
                <p>
                  According to different surveys, clients realise that their
                  conventional testing techniques need major changes in order to
                  fulfil their business objectives due to the volume and
                  velocity of changes to the ERP, CRM, and BPM systems. With the
                  help of industry best practises like RPA, AI, machine
                  learning, and DevOps, QualiTek provides to you the best in
                  class Enterprise Applications Quality Assurance practise.
                  ongoing testing
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default EASTesting;
