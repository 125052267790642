import "../../App.css";
import "./Homepage.css";
import Partners from "../Partners/Partners";


import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import ProgressBar from "../ProgressBar";
import ScrollspyNav from "react-scrollspy-nav";

import ImageSlider from "../ImageSlider";
import Footer1 from "../Footer1";
import KeyServices from "./KeyServices";
import DevopServices from "./DevopServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";
import Scrollspy from "react-scrollspy";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function HomePage() {
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();
  const footerRef = useRef(null);
  const homeRef = useRef(null);
  const keyserviceRef = useRef(null);
  const testimonialRef = useRef(null);
  const devopsRef = useRef(null);
  const [hoverImage, setHoverImage] = useState("");

  const [scrollPosition, setScroll] = useState(0);
  const [activeScrollItem, setActiveScrollItem] = useState("home");

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  const handleScroll = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        top: ref.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const setScrollPosition = (scrollPos) => {
    console.log("In scroll position settinggggggggggggg");
    setActiveScrollItem(scrollPos);
  };

  useEffect(() => {
    let progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scroll = `${(totalScroll / windowHeight) * 100}%`;
      const scrollValue = `${(totalScroll / windowHeight) * 100}`;

      setScroll(scroll);

      if (
        parseInt(scrollValue) > parseInt(keyserviceRef.offsetTop / 100) &&
        parseInt(scrollValue) < parseInt(testimonialRef.offsetTop / 100)
      ) {
        console.log("here in keyservices ref");
        setActiveScrollItem("keyservices");
      } else if (
        parseInt(scrollValue) >
          parseInt(testimonialRef.current.offsetTop / 100) &&
        parseInt(scrollValue) < parseInt(devopsRef.current.offsetTop / 100)
      ) {
        console.log("here in testimonials ref");
        setActiveScrollItem("testimonials");
      } else if (
        parseInt(scrollValue) > parseInt(devopsRef.current.offsetTop / 100) &&
        parseInt(scrollValue) < parseInt(footerRef.current.offsetTop / 100)
      ) {
        console.log("here in devops ref");
        setActiveScrollItem("devops");
      } else if (
        parseInt(scrollValue) > parseInt(footerRef.current.offsetTop / 100)
      ) {
        console.log("here in footer ref");
        setActiveScrollItem("footer");
      } else {
        setActiveScrollItem("home");
      }
    };

    window.addEventListener("scroll", progressBarHandler);

    return () => window.removeEventListener("scroll", progressBarHandler);
  });

  const progressContainerStyle = {
    background: "transparent",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
    height: "5px",
    position: "fixed",
    top: "65px",
    left: 0,
    width: "100vw",
    zIndex: 5000,
  };

  const progressBarStyle = {
    height: "5px",
    background: "green",
    width: scrollPosition,
    opacity: 0.1,
  };

  return (
    <div ref={homeRef}>
      <div className="progressive-nav-container">
        <div
          className="progressive-nav-item"
          onClick={() => {
            handleScroll(homeRef);
          }}
        >
          <FontAwesomeIcon icon={faLongArrowAltUp} size="sm" />
        </div>
        <div
          onClick={() => {
            handleScroll(keyserviceRef);
          }}
          className="progressive-nav-item"
        >
          <p>Key Services</p>
        </div>
        <div
          onClick={() => {
            handleScroll(testimonialRef);
          }}
          className="progressive-nav-item"
        >
          <p>What Our clients say</p>
        </div>
        <div
          onClick={() => {
            handleScroll(devopsRef);
          }}
          className="progressive-nav-item"
        >
          <p>Devops Services</p>
        </div>
        <div
          onClick={() => {
            handleScroll(footerRef);
          }}
          className="progressive-nav-item"
        >
          <p>ReachUs</p>
        </div>
      </div>

      <div id="home" style={{ display: "flex", flexDirection: "column" }}>
        <div className="box1">
          <ImageSlider footerRef={footerRef} />
        </div>

        <div className="keyservcies-section" id="section-1">
          <h2>
            Every day, We measure our Success by the Quality We have attained
            for the Customer !
          </h2>
          <img
            className="keyservices-section-img"
            src={require("../../Images/QualiTek-Symbol.png")}
          />
          <h2>Key Services</h2>
          <div
            ref={keyserviceRef}
            key="keyservicesId"
            className="keyservcies-img-ext-bottom"
          ></div>
        </div>

        <KeyServices/>

       

        <div
          ref={testimonialRef}
          key="testimonialsId"
          className="testimonial-ref"
          id="section-2"
        ></div>

        <div ref={devopsRef} key="devopsId" className="devops-banner-container">
          <video
            style={{ width: "100%", height: "100%" }}
            autoPlay
            loop
            muted
            playbackspeed="0.5"
          >
            <source src={require("../../Images/clouds.mp4")} type="video/mp4" />
          </video>
          <div className="devops-keyservices-banner-box">
            <div className="devops-keyservices-banner-caption">
              <h2>Make progress thrive with cloud-led innovation</h2>

              <h3>
                Initiate your <strong>Dev-Ops Journey</strong> with us
              </h3>
            </div>

            <img
              src={require("../../Images/QualiTek-Symbol.png")}
              className="keyservices-section-img-2"
            />
            <h1 className="devops-keyservices-banner-caption-h2">
              Devops Services
            </h1>
            {contentView == "webview" ? (<div
              className="keyservcies-img-ext-bottom"
              style={{ marginTop: "1%", borderTopColor: "orangered" }}
            ></div>) : (<div
              className="devservcies-img-ext-bottom"
              style={{  borderTopColor: "orangered" }}
            ></div>) }
            
          </div>
        </div>

        <DevopServices/>

        
          
          
      </div>

      <div className="end-banner" ref={footerRef} key="footerId" id="section-3">
        <Footer1 />
      </div>
    </div>
  );
}

export default HomePage;
                                                                                                                                                                                                                                                                                                                                   