import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function UsabilityTesting() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Gain real-time insights into applications with usability testing
            services
          </h2>

          <h2 className="banner-box-container-2-h2">
            Optimize Your Applications For Better User Experience
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Usability Testing Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  Do users of your application have to sift through a confusing
                  array of options in order to accomplish their goals? Are
                  clients abandoning your website before making a purchase
                  because they couldn't find the appropriate link or page?
                </p>
                <br />
                <p>
                  Usability testing can help to solve these issues, allowing
                  users to easily carry out their requests on the programme.
                  <br />
                </p>
                <p>
                  Your consumer loyalty may suffer, which will have a negative
                  effect on your sales and brand reputation. The software should
                  live up to users' expectations and be simple to learn,
                  utilise, and navigate. Before the programme is made available
                  to end users, software usability testing will help you find
                  all the usability problems, ensuring that your clients have an
                  intuitive user experience.
                </p>
              </div>
            </div>
            <div
              className="service-content-right-img"
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/usability-testing-img-1.png")}
                style={{ width: "60%" }}
              />
            </div>
            <div
              className="service-content-desc"
              style={{
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                className="service-content-right-ul"
                style={{
                  width: "100%",
                  paddingLeft: 0,
                  alignSelf: "flex-start",
                }}
              >
                <p style={{ alignSelf: "flex-start" }}>
                  The usability experts at QualiTek will assist you with the
                  user experience of your application and develop a strategy for
                  usability testing based on the users, demographics, important
                  business scenarios, etc. The team will choose a group of
                  suitable users, create the survey's questions, and assist the
                  selected users in completing it. In order to gain more
                  in-depth knowledge of the application's navigational scenarios
                  and other alternatives, the user behaviour and actions will be
                  continuously tracked in conjunction with interviews.
                </p>
                <br />

                <ul className="service-differtiater-list-body">
                  <li className="service-differtiater-list-item">
                    The following important parameters will be included in a
                    thorough Usability analysis report from QualiTek:
                  </li>
                  <li className="service-differtiater-list-item">
                    Average Application Usability Rating
                  </li>
                  <li className="service-differtiater-list-item">
                    Usability Ratings of Various countries
                  </li>
                  <li className="service-differtiater-list-item">
                    Detailed comments provided by individuals
                  </li>
                  <li className="service-differtiater-list-item">
                    The graph of Average Application Usability Rating
                  </li>
                  <li className="service-differtiater-list-item">
                    Areas which are easy to access
                  </li>
                  <li className="service-differtiater-list-item">
                    Areas where improvement is required
                  </li>
                  <li className="service-differtiater-list-item">
                    Average response given by users Post-Task
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Usability Testing Approach</h2>
        </div>

        <div
          className="service-content-right-img"
          style={{
            position: "relative",
            width: "80%",
            height: "auto",
          }}
        >
          <img
            src={require("../../Images/usability-img-2.png")}
            style={{ width: "100%" }}
          />
        </div>
        <div
          className="service-content-right-img"
          style={{
            position: "relative",
            width: "80%",
            height: "auto",
          }}
        >
          <img
            src={require("../../Images/usability-img-3.png")}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>QualiTek Differentiators</h2>
          </div>
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/usability-testing-img-3.png")}
                  style={{ width: "80%" }}
                />
              </div>
            ) : null}
            <div className="service-content-right-ul">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  The startegy provided by a pool of Usability testing experts
                </li>
                <li className="service-differtiater-list-item">
                  Based on application characteristics,business objectives and
                  user demographics sample user list making
                </li>
                <li className="service-differtiater-list-item">
                  Through the wide user community getting users onboard
                </li>
                <li className="service-differtiater-list-item">
                  Quick ramp up / ramp down ability
                </li>
                <li className="service-differtiater-list-item">
                  Comprehensive usability reports along with detailed
                  recommendations for improvement.
                </li>
              </ul>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/usability-testing-img-3.png")}
                  style={{ width: "80%" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default UsabilityTesting;
