import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function APIMicroServicesTesting() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Achieve Functionality Compliant Applications With Our Manual Testing
            Services
          </h2>

          <h2 className="banner-box-container-2-h2">
            Launch Bug Free Applications With Coincidence
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>API and Web Services Testing</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  Organizations have embraced contemporary architecture that
                  includes mobile and cloud services, and as a result, a sizable
                  composite system is hidden behind these seemingly
                  straightforward apps. For these applications to function
                  properly, the message layer's and business logic's content is
                  crucial. How to validate our Business Applications is a
                  crucial issue given that the design adheres to specific
                  protocols like XML, SOAP, REST, and others.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/api-micro-testing-img-1.png")}
                  style={{ width: "100%" }}
                />
              </div>
            ) : null}
            <div
              className="service-content-right-ul"
              style={{
                justifyContent: "flex-start",
                padding: 0,
              }}
            >
              <h2 className="service-ul-header-h2">
                QualiTek offers the following API/Web Services Testing
              </h2>
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  To test the functioning of individual APIs, using unit
                  testing.
                </li>
                <li className="service-differtiater-list-item">
                  Testing the system under various levels of user and
                  transaction load in order to validate its functionality and
                  performance
                </li>
                <li className="service-differtiater-list-item">
                  Security testing entails a number of security checks,
                  including access control, authentication, and penetration
                  testing.
                </li>
                <li className="service-differtiater-list-item">
                  Functional testing is used to verify each API layer's complete
                  functionality.
                </li>
                <li className="service-differtiater-list-item">
                  Runtime error detection involves running manual or automated
                  tests to find issues like resource leaks and exceptions
                </li>
                <li className="service-differtiater-list-item">
                  End-to-end testing of the entire system utilising the APIs is
                  known as web UI testing.
                </li>
              </ul>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/api-micro-testing-img-1.png")}
                  style={{ width: "100%" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>QualiTek Differentiators</h2>
        </div>
        <div className="service-content-type-1">
          {contentView == "mobileview" ? (
            <div className="service-content-right-img">
              <img
                src={require("../../Images/api-micro-testing-img-2.png")}
                style={{ width: "100%" }}
              />
            </div>
          ) : null}
          <div className="service-content-right-ul" style={{ paddingLeft: 0 }}>
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Unit testing – for checking the functionality of individual
                APIs.
              </li>
              <li className="service-differtiater-list-item">
                Load testing – for validation of functionality and performance
                for the system under various levels of user/ transaction load.
              </li>
              <li className="service-differtiater-list-item">
                Security testing – Involves various types of security checks
                like penetration testing, authentication, encryption, and access
                control.
              </li>
              <li className="service-differtiater-list-item">
                Functional testing – for testing end to end functionality of the
                API layer.
              </li>
              <li className="service-differtiater-list-item">
                Runtime error detection – Execution of Automated / Manual tests
                to identify problems, such as exceptions and resource leaks.
              </li>
              <li className="service-differtiater-list-item">
                Web UI testing – end to end testing of the entire system using
                the APIs.
              </li>
            </ul>
          </div>
          {contentView == "webview" ? (
            <div className="service-content-right-img">
              <img
                src={require("../../Images/api-micro-testing-img-2.png")}
                style={{ width: "100%" }}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default APIMicroServicesTesting;
