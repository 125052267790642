import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function MobileApplicationTesting() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Make Your Mobile App A Cursor To Connect Digital and Real Worlds
          </h2>

          <h2 className="banner-box-container-2-h2">
            Partner With Us To Launch Flawness Mobile Appilications On All
            Platforms
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Mobile Application Testing Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  As mobile devices play a bigger role in our daily lives, the
                  demand for mobile testing is rising. Customer success depends
                  on your ability to perform mobile testing according to your
                  company's demands.
                </p>
                <br />
                <p>
                  Mobile application testing (MAT) is the process of evaluating
                  mobile applications to make sure they function properly across
                  all platforms and browsers. Mobile testing can be automated or
                  done manually on real devices, emulators, and simulators.
                  Software, apps, and more all benefit from improved user
                  experience thanks to mobile testing. If you use the right MAT,
                  you can enhance QA by testing APIs, simulating various
                  operating systems, and catching errors in the front end and
                  back end.
                </p>
              </div>
            </div>
            <div
              className="service-content-right-desc"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/mobile-app-img-3.png")}
                style={{ width: "100%" }}
              />
            </div>
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <br/>
                <p>
                  QualiTek (mobile testing company), with its{" "}
                  <b>Advanced Mobile Test Lab</b>, extensive expertise in mobile
                  testing engagements and breadth of experience in the right
                  tools, ensures that the mobile testing is thorough and
                  cost-effective.
                </p>

                <br />
                <p style={{ alignSelf: "flex-start" }}>
                  Our Mobile Testing Lab has 250+ physical and unlimited
                  cloud-based devices from leading brands and the ability to
                  test with various network carriers on 2G, 3G, 4G and Wi-Fi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>How can we help your business ?</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                In addition to functional testing, QualiTek also offers
                end-to-end mobile testing services for a variety of devices,
                models, networks, operating systems, browsers, and locations.
                These services include functional, security, performance,
                compatibility, usability, and test automation.
              </p>
              <br />
              <p style={{ alignSelf: "flex-start" }}>
                Using different engagement models, such as managed testing
                services, project-based engagement, or staffing/T&M, is another
                way to get involved.
              </p>
              <br />
              <p style={{ alignSelf: "flex-start" }}>
                QualiTek mobile testing services also includes the following
              </p>
              <br />
            </div>
          </div>
          <div
            className="service-content-right-img"
            style={{
              position: "relative",
              width: "90%",
              height: "auto",
              display: "flex",
              alignSelf: "center",
            }}
          >
            <img
              src={require("../../Images/mobile-app-testing-img-1.png")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Specific functionality Checks</h2>
          </div>
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-ul-img">
                <img
                  src={require("../../Images/mobile-app-testing-img-2.png")}
                />
              </div>
            ) : null}

            <div className="service-content-right-ul-2">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Installation Testing
                </li>
                <li className="service-differtiater-list-item">
                  Upgrade Testing
                </li>
                <li className="service-differtiater-list-item">
                  Landscape/Portrait Mode Testing
                </li>
                <li className="service-differtiater-list-item">UI testing</li>
                <li className="service-differtiater-list-item">
                  Battery Drain Testing
                </li>
                <li className="service-differtiater-list-item">
                  Memory testing
                </li>
              </ul>
            </div>
            <div className="service-content-right-ul-2">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Interruption Testing
                </li>
                <li className="service-differtiater-list-item">
                  Carrier-Based Testing
                </li>
                <li className="service-differtiater-list-item">
                  GPS, Touch Testing
                </li>
                <li className="service-differtiater-list-item">
                  Connectivity Testing
                </li>
                <li className="service-differtiater-list-item">
                  Broken links testing
                </li>
              </ul>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/mobile-app-testing-img-2.png")}
                  style={{ width: "100%" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>QualiTek Differentiators</h2>
        </div>
        <div className="service-content-type-1">
          <div className="service-content-right-img">
            <img
              src={require("../../Images/mobile-app-testing-img-3.png")}
              style={{ width: "80%" }}
            />
          </div>
          <div className="service-content-right-ul">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Mobile testing solutions across all verticals.
              </li>
              <li className="service-differtiater-list-item">
                Creating a comprehensive test strategy upfront for optimized
                testing.
              </li>
              <li className="service-differtiater-list-item">
                Solutions and various tools for accelerating the testing.
              </li>
              <li className="service-differtiater-list-item">
                Fully equipped mobile testing lab with partnership with Perfecto
                Mobile and Device Anywhere.
              </li>
              <li className="service-differtiater-list-item">
                Testing mobile applications across a diversity of handsets and
                global operators.
              </li>
              <li className="service-differtiater-list-item">
                Experience with all industry leading tools for functional
                testing, security testing, performance testing and test
                automation.
              </li>
              <li className="service-differtiater-list-item">
                Platform and device agnostic in-house test automation framework.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default MobileApplicationTesting;
