import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function IoTTesting() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Achieve Complete Connected Applications Functionality Using Our
            Services
          </h2>

          <h2 className="banner-box-container-2-h2">
            Connect To Our IOT Testing Services
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>IoT Testing Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p style={{ alignSelf: "flex-start" }}>
                  The Internet of Things (IoT) has enabled enterprises to build
                  smart and connected applications to support multiple
                  dimensions of customer service and engagement.
                </p>
                <br />
                <p>
                  According to recent reports, the number of connected “Internet
                  of Things” is expected to reach 55 billion by 2025. The IoT
                  Testing market was valued at USD 665.47 million in 2019 and is
                  expected to reach USD 3212.10 million by 2025, at a CAGR of
                  30% over the forecast period 2020 – 2025.
                </p>
                <br />
                <p>
                  Owing to these developments in IoT, smart and connected
                  solutions will enable enterprises to monitor client’s
                  real-world experiences through software and provide services
                  across the complete range of business and consumer scenarios.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="service-content-right-ul"
              style={{ paddingLeft: 0,width:'100%'}}
            >
              <h2 className="service-ul-header-h2">
                IoT Applications Enable Real-Time Reporting to Make Informed
                Decisions
              </h2>
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  With sensors installed all over, the world is turning out to
                  be progressively connected. With sensors progressively
                  connecting all physical devices and applications, there is an
                  increased need to revamp the traditional testing approach. The
                  IoT is about reporting real-time data, permitting users to
                  make quick, and informed decisions.
                </li>
                <li className="service-differtiater-list-item">
                  To ensure that your apps and devices stand up to the real-time
                  scenarios, it is important to move part of your testing into
                  the real world with poor connectivity and less than normal
                  conditions established. This will ensure the level of quality
                  regardless of what they are put through.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div
            className="service-content-right-img"
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
              display: "flex",
              alignSelf: "center",
            }}
          >
            <img
              src={require("../../Images/IoT-img-2.jpg")}
              style={{ width: "80%" }}
            />
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div className="service-content-left-desc">
              <p>
                Across enterprises of all sizes, IoT have fueled growth in
                business operations and customer services. In coming years,
                Companies can entirely run their businesses through algorithms
                and automation, such as making self-driving cars safer, helping
                protect digital identities and even track billions of devices on
                Internet of Things (IoT).
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Use Of IoT across various Industries</h2>
        </div>

        <div
          className="service-content-right-img"
          style={{
            position: "relative",
            width: "80%",
            height: "auto",
          }}
        >
          <img
            src={require("../../Images/IoT-img-3.jpg")}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="service-content-desc"
            style={{ width: "100%", paddingBottom: "2vw" }}
          >
            <div className="service-content-left-desc">
              <p>
                IoT testing involves the end-to-end testing of the IoT ecosystem
                and it ensures the importance of multistage validation. It is
                essential to test the combination of these IoT devices, phones
                and apps. This testing process specifically requires real-world
                IoT testing that is critical for any connected products success.
              </p>
              <br />
              <p>
                With today’s enterprises increasing need to deliver faster and
                quicker smart products across business verticals, these IoT
                devices have a huge demand to access, create and share data from
                one device to another. This necessitates the proper transfer of
                information between IoT devices to deliver great customer
                experience and ease of usage of information which demands that
                these devices should be tested with different IoT testing
                approaches to ensure they deliver seamless performance. With the
                variety of platforms, devices and networks, there is significant
                expertise required for IoT Testing Services.
              </p>
              <br />
              <p>
                QualiTek provides end-to-end IoT testing services, including
                Device Interoperability, Performance, Security, API, User
                Experience, End-to-End functional testing and test automation
                across different devices, models, networks, operating systems,
                browsers, and locations.
              </p>
            </div>
          </div>
          <div
            className="service-content-right-img"
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
              display: "flex",
              alignSelf: "center",
            }}
          >
            <img
              src={require("../../Images/IoT-img-4.jpg")}
              style={{ width: "80%" }}
            />
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Challenges in testing IoT based solutions</h2>
          </div>
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/iot-testing-img-3.png")}
                  style={{ width: "100%" }}
                />
              </div>
            ) : null}
            <div
              className="service-content-right-ul"
              style={{ paddingLeft: 0 }}
            >
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  <strong>Security and data privacy:</strong>
                  Impending security vulnerabilities across various layers of
                  IoT system
                </li>
                <li className="service-differtiater-list-item">
                  <strong>Real-time complexities:</strong>
                  IoT applications can have various, real-time scenarios and its
                  use cases are complex
                </li>
                <li className="service-differtiater-list-item">
                  <strong>Dynamic environment:</strong>
                  With millions of sensors and devices in combination with
                  intelligent software, IoT has a dynamic environment, unlike
                  application testing that is performed in a defined
                  environment.
                </li>
                <li className="service-differtiater-list-item">
                  <strong>Expertise to automate:</strong>
                  Due to numerous scenarios and dependency on sensors and
                  devices, automation has become a challenging process
                </li>
                <li className="service-differtiater-list-item">
                  <strong>Scalability of the system:</strong>
                  Building a test environment to measure functionality along
                  with scalability and consistency is challenging
                </li>
              </ul>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/iot-testing-img-3.png")}
                  style={{ width: "80%" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>QualiTek Differentiators</h2>
        </div>
        <div className="service-content-type-1">
          <div className="service-content-left-img">
            <img
              src={require("../../Images/iot-testing-img-4.png")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-right-ul">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                <strong>Comprehensive QA strategy:</strong> QualiTek’s
                comprehensive QA strategy can handle the unique requirements and
                challenges associated with validating IoT set-up, Test Execution
                and Result Verification as well as methodology to conduct
                standard and specialized testing of your IoT applications
              </li>
              <li className="service-differtiater-list-item">
                <strong>Robust Testing Solution:</strong> Our test solution
                includes a combination of tests including IoT test automation
                tools, frameworks, and devices. Our extensive test framework
                provides capabilities required to perform load simulation,
                security verification, and functional validation. Furthermore,
                our in-house test automation solution is combined with
                simulators and can enable end to end rigorous automation
              </li>
              <li className="service-differtiater-list-item">
                <strong>Domain Experience:</strong> QualiTek's QA engineers have
                worked on business processes in various domains of IoT
                applications that most development teams lack. Global team of QA
                professionals with a capability to scale up at both onsite and
                offshore
              </li>
              <li className="service-differtiater-list-item">
                <strong>Improved Time-to-market:</strong>
                We leverage our ready-to-deploy test automation framework,
                Tx-Automate to achieve a minimum of 20% improved time-to-market
              </li>
              <li className="service-differtiater-list-item">
                <strong>Realtime Reporting & Insight Utilization:</strong>
                Enables instant feedback which helps to resolve issues at the
                earliest. We use extensive reports, metrics and insights that
                help in making better decisions for release.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default IoTTesting;
