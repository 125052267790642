import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function DataWarehousingTesting() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            End-to-End specialized Services help to get a Holistic View of Your
            Data’s Health
          </h2>

          <h2 className="banner-box-container-2-h2">
            Protect Your Data Loss And Embrace Effective ETL Testing Strategy
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Data WareHouse and ETL Testing services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  In order to do data warehouse testing and ETL testing while
                  spotting flaws before they manifest, Qualitek has a team of
                  highly qualified testing specialists. We make sure to offer
                  our clients a thorough process of software quality analysis
                  and help organisations around the world identify problems and
                  lower their degree of risk.
                </p>
                <br />
                <p>
                  Our main goals are to help the businesses that work with us by
                  early defect detection, cost reduction, and risk reduction.
                  Decision-making at all levels of business is increasingly
                  reliant on historical data. Due to the growing amount of data,
                  the majority of businesses are investing in the most
                  up-to-date solutions to create a strong data warehouse. To
                  assure the success of your DW and BI implementation, it is
                  hard and requires a comprehensive approach to assess the data
                  correctness, performance, and security. The specific
                  difficulties of DW and Big data analytics testing are
                  addressed by QualiTek, which has a wealth of experience in
                  analytics testing, DW, and big data testing engagements. We
                  test the DW apps thoroughly, from the data source to the
                  front-end BI applications, making sure that any flaws are
                  found early on.
                </p>
                <br />
                <p>
                  Historical data is increasingly important as a tool for
                  decision-making at all levels of company. We use ETL testing
                  and validation approaches that ensure Production
                  reconciliation and further confirm that a business's
                  information is correct and dependable in order to make sure
                  your company gets the most out of the data warehouse. We
                  guarantee that your database will be able to easily respond to
                  the most recent performance and security updates. Our
                  methodical approach will keep you protected everywhere!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="service-content-right-ul"
              style={{ paddingLeft: 0 }}
            >
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  ETL Testing for Production Validation
                </li>
                <li className="service-differtiater-list-item">
                  Testing for application upgrades
                </li>
                <li className="service-differtiater-list-item">
                  Validation Examination
                </li>
                <li className="service-differtiater-list-item">
                  Verifying the accuracy of the data
                </li>
                <li className="service-differtiater-list-item">
                  Metadata Validation
                </li>
                <li className="service-differtiater-list-item">
                  Quality Control
                </li>
                <li className="service-differtiater-list-item">
                  Testing Data Transformation
                </li>
                <li className="service-differtiater-list-item">
                  GUI Evaluation
                </li>
                <li className="service-ul-header-h2">
                  Our Method for Testing Databases
                </li>
                <br />
                <p className="service-differtiater-list-item">
                  Our database testing strategy controls the calibre of the user
                  experience when interacting with an app for a customer.We use
                  a routine testing process that guarantees high-quality
                  results:
                </p>

                <li className="service-differtiater-list-item">
                  running the test plan
                </li>
                <li className="service-differtiater-list-item">
                  Examine the functional specifications and database design
                  guidelines.
                </li>
                <li className="service-differtiater-list-item">
                  Once the issue has been resolved, retest Creating thorough
                  test plans and test scenarios and Record each defect.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default DataWarehousingTesting;
