import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function TestDataManagement() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Achieve Functionality Compliant Applications With Our Manual Testing
            Services
          </h2>

          <h2 className="banner-box-container-2-h2">
            Launch Bug Free Applications With Confidence
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Test Data Management Services</h2>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  Most businesses struggle with test data management due to
                  rising test data volumes, a variety of data kinds, intricate
                  linkages, and data sensitivity. For business functionality to
                  be tested accurately and effectively in the test environment,
                  high quality data is essential. Copying production data for
                  the test could put the business at risk for several privacy
                  and compliance issues. You risk jeopardising your testing
                  cycles and accruing significant infrastructure expenditures if
                  you don't have a solid test data management plan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="service-content-desc"
              style={{ width: "100%", justifyContent: "flex-start" }}
            >
              <div
                className="service-content-right-ul"
                style={{ width: "100%", paddingLeft: 0 }}
              >
                <h2 className="service-ul-header-h2">
                  The following three issues will be generally covered by the
                  test data management solution:
                </h2>
                <ul className="service-differtiater-list-body">
                  <li className="service-differtiater-list-item">
                    the application's real-world user interaction is simulated
                  </li>
                  <li className="service-differtiater-list-item">
                    simulating exceptional situations.
                  </li>
                  <li className="service-differtiater-list-item">
                    putting the system under real-time load while doing
                    non-functional testing
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-container">
        <div className="service-content-box">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div
                className="service-content-left-desc"
                style={{ width: "100%" }}
              >
                <p style={{ alignSelf: "flex-start" }}>
                  Qualitek has extensive expertise in all three of the
                  aforementioned approaches and can work with any firm to
                  implement them and produce a high-quality end result.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default TestDataManagement;
