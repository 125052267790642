import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function AITesting() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            When Powerful Technology Meets Effective Implementation and Internal
            Process Management Your Journey Leads To New Heights Of Business
            Success
          </h2>

          <h2 className="banner-box-container-2-h2">
            Embrace AI Testing To Shorten Testing Time And Release Quality
            Software Faster
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>AI Testing Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  The most recent technology being used in all fields and
                  sectors is artificial intelligence (AI). AI has been
                  permitting significantly dominant outcomes as it continues to
                  infiltrate fields that were previously only accessible to
                  human expertise. In a similar vein, AI is being used in
                  software testing to simplify the automation testing process
                  and produce higher-quality results.
                </p>
                <br />
                <p>
                  In order to make testing smarter, more effective, and more
                  efficient, enterprises are implementing AI-based tooling and
                  processes in software testing, according to the World Quality
                  Report 2019-2020. The use of AI in software testing will
                  typically improve the overall testing process quicker,
                  clearer, easier, and more cost-effective. Software testers
                  will be able to use AI to advance the testing process and give
                  firms higher-quality findings thanks to the strategic platform
                  that AI-based testing will offer. The continuous testing
                  process has long been supported by test automation tools.
                  However, it is now important to use more efficient tools and
                  methods, such as QA testing and integrating AI into software,
                  in order to create high-quality software and guarantee a
                  positive customer experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>QualiTek AI Application Testing Methods</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div
                className="service-content-right-img"
                style={{
                  position: "relative",
                  width: "80%",
                  height: "auto",
                }}
              >
                <img
                  src={require("../../Images/AI-img-1.png")}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>
              QualiTek Exclusive <b>AI Bots Testing</b> Process Overview
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  In order to make testing smarter, more effective, and more
                  efficient, enterprises are implementing AI-based tooling and
                  processes in software testing, according to the World Quality
                  Report 2019-2020. The use of AI in software testing will
                  typically improve the overall testing process quicker,
                  clearer, easier, and more cost-effective. Software testers
                  will be able to use AI to advance the testing process and give
                  firms higher-quality findings thanks to the strategic platform
                  that AI-based testing will offer. The continuous testing
                  process has long been supported by test automation tools.
                  However, it is now important to use more efficient tools and
                  methods, such as QA testing and integrating AI into software,
                  in order to create high-quality software and guarantee a
                  positive customer experience
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Our Approach for Testing AI Bots</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div
                className="service-content-right-img"
                style={{
                  position: "relative",
                  width: "75%",
                  height: "auto",
                }}
              >
                <img
                  src={require("../../Images/AI-img-2.png")}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Business Benefits With Our AI Testing Of Complex AI Apps</h2>
        </div>
        <div className="service-content-type-1">
          <div className="service-content-left-img">
            <img
              src={require("../../Images/ai_testing_img_1.png")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-right-ul">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Shortens the time to run a test and quickly finds any possible
                defects.
              </li>
              <li className="service-differtiater-list-item">
                Ensures the entire testing process becomes more efficient
              </li>
              <li className="service-differtiater-list-item">
                Helps QA teams to be not overloaded with work while testing
                large volumes of data
              </li>
              <li className="service-differtiater-list-item">
                Produces test results very fast
              </li>
              <li className="service-differtiater-list-item">
                AI testing helps to be a smart assistant in running numerous
                repetitive tasks most efficiently
              </li>
              <li className="service-differtiater-list-item">
                Helps to increase testing productivity through powerful reports,
                metrics and delivers real-time insights
              </li>
              <li className="service-differtiater-list-item">
                Helps to reduce time-consuming manual testing so teams can focus
                on other complex tasks or creating innovative new features
              </li>
              <li className="service-differtiater-list-item">
                Helps to free human resources to perform more creative work in
                testing
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>QualiTek Differentiators with respect to AI Testing</h2>
          </div>
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-img">
                <img src={require("../../Images/AI-img-4.png")} />
              </div>
            ) : null}
            <div
              className="service-content-right-ul"
              style={{ paddingLeft: 0 }}
            >
              <ul
                className="service-differtiater-list-body"
                style={{ marginLeft: "5%" }}
              >
                <li className="service-differtiater-list-item">
                  Strong experience in major tools to focus on Intelligent
                  automated continuous testing
                </li>
                <li className="service-differtiater-list-item">
                  20+ Person Years of experience in using various tools for
                  providing Intelligent Automation Solution
                </li>
                <li className="service-differtiater-list-item">
                  Extensive experience in enabling CI/CD pipeline using industry
                  tools – based on QualiTek Automated accelerators
                </li>
                <li className="service-differtiater-list-item">
                  100+ Test consultants for next-gen tools
                </li>
                <li className="service-differtiater-list-item">
                  Global delivery team for project execution and availability
                </li>
                <li className="service-differtiater-list-item">
                  Expertise in using AI in Test Automation with partner tools
                  and in-house accelerators
                </li>
              </ul>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/AI-img-4.png")}
                  style={{ width: "80%" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default AITesting;
