import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function PerformanceTesting() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Performance and load testing delivers stable applications at peak
            loads
          </h2>

          <h2 className="banner-box-container-2-h2">
            Predict Application Performance At High Loads To Ensure Customer
            Loyalty
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Performance Testing Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  The success of your business depends on the reliable and
                  consistent functioning of your business-critical apps.
                  However, performance problems with an application are
                  frequently discovered in real-world situations, which causes
                  serious harm to your brand's reputation. In order to determine
                  application behaviour at peak performance and scalability
                  levels, thorough web performance testing of the apps is
                  required.
                </p>
                <br />
                <p>
                  With QualiTek's assistance, you can anticipate application
                  behaviour and performance in a simulated, real-world setting,
                  helping you avoid unpleasant situations that would otherwise
                  annoy consumers and reduce profits.
                </p>
                <br />
              </div>
            </div>
            <div
              className="service-content-right-img"
              style={{
                position: "relative",
                width: "90%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/Performance-testing-1.jpg")}
                style={{ width: "100%" }}
              />
            </div>
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  We make sure the application can scale to additional future
                  events like product releases, acquisitions, new clients,
                  regulatory changes, and ongoing business expansion while still
                  being responsive and dependable enough to handle peak load
                  days like a Black Friday sale. We also confirm that the
                  applications can fulfil any contractual responsibilities and
                  performance SLAs.
                </p>
                <br />
                <p>
                  We provide load testing services for n-tier web applications,
                  client-server applications, SOA-based solutions, COTS
                  solutions, and applications across domains and technologies.
                  In addition to expertise in end-to-end application performance
                  testing, which includes network, database, hardware, etc., we
                  have experience with all of these. Response times,
                  break-points, peak loads, memory leaks, resource utilisation,
                  uptime, and other information are included in a thorough
                  performance testing report that we produce for the
                  application. Working directly with various IT departments
                  within your organisation, we offer clear and practical
                  application tuning advice and assist you in putting them into
                  practise.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>How Can We Help?</h2>
        </div>
        <div className="service-content-type-1">
          {contentView == "mobileview" ? (
            <div className="service-content-right-ul-img">
              <img
                src={require("../../Images/performance-testing-img-2.png")}
                style={{ width: "80%" }}
              />
            </div>
          ) : null}
          <div className="service-content-right-ul" style={{ paddingLeft: 0 }}>
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                We assist you in recognising every scenario that is important
                for your business.
              </li>
              <li className="service-differtiater-list-item">
                We aid in the performance benchmarking of your application.
              </li>
              <li className="service-differtiater-list-item">
                For performance testing of your servers and application, develop
                specialised and reusable solutions.
              </li>
              <li className="service-differtiater-list-item">
                We support you in implementing online monitoring tools.
              </li>
              <li className="service-differtiater-list-item">
                To determine the degree of reliability and availability of your
                application, we assist you with performance testing solutions.
              </li>
              <li className="service-differtiater-list-item">
                To ensure that the application response times are within the
                defined ranges, perform performance testing on your apps.
              </li>
            </ul>
          </div>
          {contentView == "webview" ? (
            <div className="service-content-right-img">
              <img
                src={require("../../Images/performance-testing-img-2.png")}
                style={{ width: "70%" }}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Types Of Services We Offer</h2>
          </div>
          <div className="service-content-type-1">
            <div className="service-content-left-img">
              <img
                src={require("../../Images/performance-testing-img-3.png")}
                style={{ width: "70%" }}
              />
            </div>
            <div className="service-content-right-ul-2">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Scalability testing
                </li>
                <li className="service-differtiater-list-item">UI testing</li>
                <li className="service-differtiater-list-item">
                  Endurance testing
                </li>
                <li className="service-differtiater-list-item">
                  Stress testing
                </li>
                <li className="service-differtiater-list-item">
                  Component performance testing
                </li>
                <li className="service-differtiater-list-item">
                  System level performance testing
                </li>
                <li className="service-differtiater-list-item">
                  Volume testing
                </li>
              </ul>
            </div>
            <div className="service-content-right-ul-2">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  System Characterization
                </li>
                <li className="service-differtiater-list-item">
                  Performance Code Reviews/Profiling
                </li>
                <li className="service-differtiater-list-item">
                  Customized solutions
                </li>
                <li className="service-differtiater-list-item">
                  Setup performance CoEs
                </li>
                <li className="service-differtiater-list-item">
                  Load testing services over cloud
                </li>
                <li className="service-differtiater-list-item">
                  Continuous performance testing
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>QualiTek Differentiators</h2>
        </div>
        <div className="service-content-type-1">
          {contentView == "mobileview" ? (
            <div className="service-content-right-ul-img">
              <img
                src={require("../../Images/performance-testing-img-4.png")}
              />
            </div>
          ) : null}
          <div className="service-content-right-ul" style={{ paddingLeft: 0 }}>
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Identify potential performance issues during the development
                phase using Component level performance testing Early Testing
                leads to Early Detection
              </li>
              <li className="service-differtiater-list-item">
                Initial plans for scalability tests
              </li>
              <li className="service-differtiater-list-item">
                By identifying load and performance issues a cost effective
                solution can be made before the issues impact.
              </li>
              <li className="service-differtiater-list-item">
                To understand the application behaviour detailed test result
                documentation and result analysis provision to the stake holders
              </li>
              <li className="service-differtiater-list-item">
                With our Competitive pricing and having access to our SMEs along
                with regular resources get better results
              </li>
              <li className="service-differtiater-list-item">
                With our engineering services get end to end full-cycle
                performance testing
              </li>
              <li className="service-differtiater-list-item">
                open source (e.g. JMeter), and third-party tools for performance
                testing (LoadRunner, VSTS, LoadComplete, etc.)
              </li>
              <li className="service-differtiater-list-item">
                Make your system capable of real world usage
              </li>
            </ul>
          </div>
          {contentView == "webview" ? (
            <div className="service-content-right-img">
              <img
                src={require("../../Images/performance-testing-img-4.png")}
                style={{ width: "80%" }}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default PerformanceTesting;
