import React, { useImperativeHandle, forwardRef, useRef } from "react";
import Socials from "./Socials";
import WhyUs from "./WhyUs";
import ContactUs from "./ContactUs";
import "./Footer1.css";
import { Link, NavLink, BrowserRouter } from "react-router-dom";

const Footer1 = forwardRef((props, ref) => {
  const footerRef = useRef();
  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      footerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    },
  }));
  return (
    <div className="footer-box">
      <div className="footercontainer">
        <div className="footercontainer2">
          <img
            className="footer-img"
            src={require("../Images/QualiTek-Symbol.png")}
          />

          <Link className="footer-links" to={"/aboutus"}>
            About Us
          </Link>
          <Link className="footer-links" to={"/contact"}>
            Contact Us
          </Link>
          <Link className="footer-links" to={"/careers"}>
            Careers
          </Link>
        </div>
        <div className="footercontainer2">
          <Socials />
        </div>
      </div>
      <div className="footercontainer3">
        <Link className="footer-policy-links" to={"./aboutus"}>
          Privacy Policy
        </Link>

        <Link className="footer-policy-links" to={"./aboutus"}>
          Terms & Conditions
        </Link>

        <span>© 2022 QualiTek.All rights Reserved </span>
      </div>
    </div>
  );
});

export default Footer1;
