import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function SecurityTesting() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Safeguard your applications from cyber threats with efficient
            security testing
          </h2>

          <h2 className="banner-box-container-2-h2">
            Protect Your Business Critical Applications From Cyber
            vulnerabilities
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Security Testing Services</h2>
          </div>
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-ul-img">
                <img
                  src={require("../../Images/security-testing-img-1.png")}
                  style={{ width: "80%" }}
                />
              </div>
            ) : null}
            <div className="service-content-desc">
              <div className="service-content-left-desc">
                <p>
                  Enterprise's top worries include application availability,
                  customer data security, and corporate security as the cyber
                  world becomes more and more open to attacks. Any security
                  breach may have far-reaching effects, such as losing the trust
                  of customers and facing legal ramifications. We advise hiring
                  security testing services for your application to prevent this
                  scenario.
                </p>
                <br />
                <p>
                  With the help of its group of Certified Ethical Hackers (CEH),
                  QualiTek can make sure that your application complies with the
                  stated security criteria, such as confidentiality,
                  authorisation, authentication, availability, and integrity.
                </p>
              </div>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/security-testing-img-1.png")}
                  style={{ width: "80%" }}
                />
              </div>
            ) : null}
          </div>
          <div className="service-content-type-1">
            <div className="service-content-left-img">
              <img
                src={require("../../Images/owasp.jpg")}
                style={{ width: "70%" }}
              />
            </div>
            <div className="service-content-desc">
              <div
                className="service-content-left-desc"
                style={{ marginTop: "5%" }}
              >
                <p>
                  With our experience evaluating a variety of apps for security
                  threats, QualiTek is one of the best security testing firms.
                  We make sure that your application is thoroughly tested for
                  all potential threats and vulnerabilities.
                </p>
                <br />
                <p>
                  In order to meet application-specific needs, we also adhere to
                  PCI-DSS, HIPAA, SOX, WAHH, OSSTM, WASC, and NIST Standards in
                  addition to the OWASP (Open Web Security Project)
                  recommendations. These are a series of in-depth tests for
                  evaluating the security of your web application and making
                  sure that any vulnerabilities are not overlooked while
                  testing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>QualiTek Differentiators</h2>
        </div>
        <div className="service-content-type-1">
          {contentView == "mobileview" ? (
            <div className="service-content-right-img">
              <img
                src={require("../../Images/security-testing-img-3.png")}
                style={{ width: "100%" }}
              />
            </div>
          ) : null}
          <div className="service-content-right-ul" style={{ paddingLeft: 0 }}>
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Good resource pool of Certified Ethical Hackers.
              </li>
              <li className="service-differtiater-list-item">
                Compliance with international standards like OSSTMM and OWASP.
              </li>
              <li className="service-differtiater-list-item">
                Vendor independence and in-depth knowledge of important security
                technologies.
              </li>
              <li className="service-differtiater-list-item">
                Each vulnerability is properly classified in the report, along
                with a mitigation plan.
              </li>
              <li className="service-differtiater-list-item">
                Making sure there are no false positives using an exploitation
                snapshot.
              </li>
              <li className="service-differtiater-list-item">
                Full cycle of regression testing.
              </li>
              <li className="service-differtiater-list-item">
                A vulnerability-free application with iterative release
                planning.
              </li>
              <li className="service-differtiater-list-item">
                Supported Tools: Acunetix, Cenzic Hailstorm, Burp Suite Pro, IBM
                App Scan, HP Web Inspect, and other open source tools.
              </li>
            </ul>
          </div>
          {contentView == "webview" ? (
            <div className="service-content-right-img">
              <img
                src={require("../../Images/security-testing-img-3.png")}
                style={{ width: "100%" }}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>GDPR Compliance Testing</h2>
          </div>
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/security-testing-img-2.png")}
                  style={{ width: "60%" }}
                />
              </div>
            ) : null}
            <div className="service-content-desc">
              <div className="service-content-left-desc">
                <p>
                  The GDPR became effective on May 25, 2018. It is a framework
                  for the protection of personal data of individuals across the
                  European Union. By May 25, 2018, the majority of businesses
                  that handle personal data for EU citizens (whether they are
                  clients, partners, or workers) must comply with the GDPR.
                  After May 2018, businesses that process personal data are
                  subject to stricter regulations on the collection and use of
                  that data.
                </p>
                <br />
                <p>
                  All businesses must comply with the law, failing to do so
                  might result in a punishment of up to 4% of annual global
                  sales, or 20 million Euros, whichever is larger. GDPR is more
                  than simply a means of complying with the law; it's also a
                  chance to foster trust, promote customer centricity, and open
                  doors for business.
                </p>
              </div>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img
                  src={require("../../Images/security-testing-img-2.png")}
                  style={{ width: "60%" }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default SecurityTesting;
