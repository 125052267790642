import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function AccessibilityTesting() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Make Your Applications To Reach Every Corner Of The Digital World
          </h2>

          <h2 className="banner-box-container-2-h2">
            Comprehensive Accessibility Testing Services Ensures Equal Access To
            All
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Accessibility Testing Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  Nowadays, using multiple applications has ingrained itself
                  into everyday life. Around the world, people use several app
                  types to carry out a variety of jobs. The same apps are still
                  difficult to access for a substantial portion of our society,
                  which includes more than a billion people with various
                  disabilities (such as visual and hearing impairments,
                  cognitive impairments, movement impairments, etc.). so that
                  everyone has access to apps equally (with disabilities &
                  special needs)
                </p>
                <br />
                <p>
                  Accessibility testing is the process of evaluating a web
                  application to ensure that every user can readily access the
                  website. Web accessibility testing is the specialist and
                  committed field of testing that assists in ensuring that
                  websites are actually effective in this area.
                </p>
                <br />
                <p>
                  We at QualiTek support a wide array of accessibility testing
                  services because we favour an equitable society. We make sure
                  that centralised accessibility practises are followed within
                  the application by having a strong grasp of accessibility
                  testing regulations such as Section 508, UK-Equality Act,
                  Stanca Act, Rights of Persons with Disabilities Act, 2016, and
                  W3Cs WCAG 2.0, WCAG 2.1 (Level A: 30, Level AA: 20, Level AAA:
                  28), WCAG 2.2 (Level A: 34, Level AA: 24, Level AAA: 28), etc.
                  Businesses can profit greatly with accessibility testing. To
                  start, an applicant who complies with accessibility standards
                  might shield a company from potential legal challenges.
                </p>
                <br />
                <p>
                  Finally, a readily usable app will assist companies in
                  expanding user outreach & user engagement. Additionally, this
                  will improve the reputation and image of the company as a
                  whole.
                </p>
                <p>
                  Manual Web Accessibility Testing - We thoroughly assess the
                  software and confirm that the website complies with W3C
                  standards. All scenarios are personally tried to make sure
                  they meet to the WCAG 2.0 and 2.1 success criteria.
                </p>
              </div>
            </div>
          </div>

          <div className="service-content-box" style={{ width: "80%" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h2>
                Our Accessibility Testing Ensures to Overcome the Below Defined
                Disabilities
              </h2>
            </div>
            <div
              className="service-content-type-1"
              style={{
                width: "60%",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              {contentView == "mobileview" ? (
                <div className="service-content-right-img">
                  <img
                    src={require("../../Images/access-testing-img-1.png")}
                    style={{ width: "150%" }}
                  />
                </div>
              ) : null}
              <div
                className="service-content-right-ul-2"
                style={{ paddingLeft: 0 }}
              >
                <ul className="service-differtiater-list-body">
                  <li className="service-differtiater-list-item">
                    Color blindness
                  </li>
                  <li className="service-differtiater-list-item">
                    Partial blindness
                  </li>

                  <li className="service-differtiater-list-item">
                    Impaired Vision
                  </li>
                  <li className="service-differtiater-list-item">
                    Mobility Impairment
                  </li>
                </ul>
              </div>

              <div
                className="service-content-right-ul-2"
                style={{ paddingLeft: 0 }}
              >
                <ul className="service-differtiater-list-body">
                  <li className="service-differtiater-list-item">Deafness</li>
                  <li className="service-differtiater-list-item">
                    Partial deafness
                  </li>
                  <li className="service-differtiater-list-item">
                    Moto Impairment
                  </li>
                </ul>
              </div>
              {contentView == "webview" ? (
                <div className="service-content-right-img">
                  <img
                    src={require("../../Images/access-testing-img-1.png")}
                    style={{ width: "150%" }}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="service-content-box">
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h2>
                Our Accessibility Testing Ensures The Below Application Features
                Tested
              </h2>
            </div>
            <div
              className="service-content-type-1"
              style={{
                width: "60%",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <div className="service-content-right-ul-3">
                <ul className="service-differtiater-list-body">
                  <li className="service-differtiater-list-item">
                    Color Contrast
                  </li>
                  <li className="service-differtiater-list-item">
                    Image Size and Color
                  </li>
                  <li className="service-differtiater-list-item">
                    Page Zoom Feature
                  </li>

                  <li className="service-differtiater-list-item">UI testing</li>
                  <li className="service-differtiater-list-item">
                    Focus Areas
                  </li>
                </ul>
              </div>
              <div className="service-content-right-ul-3">
                <ul className="service-differtiater-list-body">
                  <li className="service-differtiater-list-item">Navigation</li>
                  <li className="service-differtiater-list-item">
                    Content Readability
                  </li>
                  <li className="service-differtiater-list-item">Deafness</li>

                  <li className="service-differtiater-list-item">Multimedia</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="service-content-box">
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h2>Accessibility Testing Services</h2>
            </div>
            <div
              className="service-content-right-desc"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/accessibility-testing-img-1.jpg")}
                style={{ width: "80%" }}
              />
            </div>
            <div
              className="service-content-right-desc"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/accessibility-testing-img-2.jpg")}
                style={{ width: "80%" }}
              />
            </div>
            <div
              className="service-content-right-desc"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/accessibility-testing-img-3.jpg")}
                style={{ width: "80%" }}
              />
            </div>
            <div
              className="service-content-right-desc"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img
                src={require("../../Images/accessibility-testing-img-4.jpg")}
                style={{ width: "80%" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Accessibility Testing Process</h2>
        </div>
        <div className="service-content-type-1">
          <div className="service-content-left-img">
            <img
              src={require("../../Images/access-testing-img-2.png")}
              style={{ width: "100%" }}
            />
          </div>

          <div className="service-content-right-ul">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                QualiTek accessibility testing team interacts with the client to
                understand in-depth the accessibility requirements
              </li>
              <li className="service-differtiater-list-item">
                Testers identify the number of screens to be tested and identify
                the browsers and platforms to be tested
              </li>
              <li className="service-differtiater-list-item">
                After identifying the screen to be tested, further the testers
                identify the accessibility testing tasks of any website or
                native app, or mobile app
              </li>
              <li className="service-differtiater-list-item">
                Teams perform accessibility testing to ensure the application is
                accessible by bringing it in line with W3C’s, i.e., WCAG
                2.0/WCAG 2.1 and WCAG 2.2 guidelines to the Act Section 508 of
                the US Rehabilitation Act of 1973, Rights of Persons with
                Disabilities Act, 2016 (RPWD) & Equality Act 2010
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Accessibility Testing Services Types</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <h2>Manual Web Accessibility Testing</h2>
                <p>
                  We conduct a comprehensive evaluation of the software and
                  check web compliance with W3C standards. We manually test all
                  scenarios and ensure they are aligned with the WCAG 2.0 and
                  2.1 success criteria.
                </p>
                <br />
                <h2>Automated Web Accessibility Testing</h2>
                <p>
                  We combine the industry-leading automation testing tools to
                  deliver effective automated accessibility testing services. We
                  also provide detailed custom reports to clients to help them
                  make informed decisions.
                </p>
                <h2>Mobile Accessibility Testing</h2>
                <p>
                  We enable an entire range of conformance evaluations for
                  mobile applications to ensure these apps align with globally
                  acceptable accessibility standards. Our teams have hands-on
                  expertise with industry-leading accessibility testing tools to
                  deliver comprehensive accessibility testing on mobile devices.
                </p>
                <h2>Hardware Testing</h2>
                <p>
                  Our accessibility testing experts conduct an in-depth
                  evaluation of the product’s hardware accessibility to ensure
                  conformance with section 508 and other standards. Our testing
                  team includes people with and without disabilities that check
                  the hardware accessibility, leveraging various testing tools
                  and other assistive technologies. We ensure product hardware
                  is seamlessly accessible to all, including people with
                  specific disabilities.
                </p>
                <h2>Accessibility Testing Reporting Structure</h2>
                <p>
                  Voluntary Product Accessibility Template (VPAT) Our teams
                  conduct an in-depth analysis of the VAPT document. This report
                  provides a comprehensive analysis of the business's
                  conformance to accessibility standards set by Section 508 of
                  the Rehabilitation Act. Our VPAT documentation report outlines
                  the key accessibility requirements, provides a structure for
                  the vendor to outline the compliance level, and contains
                  explanatory remarks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Accessibility Testing Standards</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-content-desc" style={{ width: "100%" }}>
            <div
              className="service-content-right-img"
              style={{
                position: "relative",
                width: "80%",
                height: "auto",
              }}
            >
              <img
                src={require("../../Images/access-testing-img-4.png")}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Country-Specific Standards</h2>
          </div>
          <div className="service-content-type-1">
            <div className="service-content-left-img">
              <img
                src={require("../../Images/accessibility-testing-img-7.jpg")}
                style={{ width: "70%" }}
              />
            </div>
            <div className="service-content-right-ul">
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  UK – Equality Act 2010
                </li>
                <li className="service-differtiater-list-item">
                  US – Section 508 of the US Rehabilitation Act of 1973
                </li>
                <li className="service-differtiater-list-item">
                  India – Rights of Persons with Disabilities Act, 2016 (RPD)
                </li>
                <li className="service-differtiater-list-item">UI testing</li>
                <li className="service-differtiater-list-item">
                  Country-Specific Standards – Many other countries have laws
                  that favour or mandate accesbility of websites and
                  applications e.g Sweden , Phillipines, etc.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>QualiTek Differentiators</h2>
        </div>
        <div className="service-content-type-1">
          {contentView == "mobileview" ? (
            <div className="service-content-right-img">
              <img
                src={require("../../Images/access-testing-img-3.png")}
                style={{ width: "100%" }}
              />
            </div>
          ) : null}
          <div className="service-content-right-ul">
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                we ensure all business-critical applications are accessible to
                all by bringing them in line with the latest accessibility
                standards like W3C’s, i.e., WCAG 2.0/WCAG 2.1/WCAG 2.2, Section
                508, ADA, RPWD & Stanca Act.
              </li>
              <li className="service-differtiater-list-item">
                In-house teams consisting of highly skilled teams of experts,
                including differently-abled, who perform accessibility testing
                of applications
              </li>
              <li className="service-differtiater-list-item">
                Exposure to industry-leading open-source and commercial
                accessibility testing tools like AChecker, WAVE, aXe, Web
                Accessibility Toolbar (WAT), Powermapper Sort site,
                Accessibility Insight, etc.,
              </li>
              <li className="service-differtiater-list-item">
                Exposure working with all industry-leading screen readers for
                accessibility testing like Job Access with Speech (JAWS),
                NonVisual Desktop Access (NVDA), Windows Narrator, TalkBack,
                VoiceOver, and ChromeVox
              </li>
              <li className="service-differtiater-list-item">
                Our teams have hands-on expertise working with all leading
                mobile accessibility testing tools such as TalkBack, VoiceOver,
                etc.
              </li>
              <li className="service-differtiater-list-item">
                Our teams also deliver a detailed report in the Voluntary
                Product Accessibility Template (VPAT) to stakeholders for
                helping them make informed decisions
              </li>
              <li className="service-differtiater-list-item">
                Seamless customer support available 24×7
              </li>
            </ul>
          </div>
          {contentView == "webview" ? (
            <div className="service-content-right-img">
              <img
                src={require("../../Images/access-testing-img-3.png")}
                style={{ width: "100%" }}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default AccessibilityTesting;
