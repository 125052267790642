import "../../App.css";

import React, { useRef } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function TestAdvisory() {
  const footerRef = useRef(null);

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>Increase Your QA Maturity with Test Advisory Services</h2>

          <h2 className="banner-box-container-2-h2">
            Adopt the appropriate QA approach to align your business roadmap.
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Test Advisory Services</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>What if I have no idea where to begin?</p>
                <p>
                  You've got a testing team, but how do you get them going and
                  keep them on course? Uncertain of what you're looking for but
                  in need of a testing team? We're here to help with our test
                  advice services. We can assist you in selecting the ideal
                  approach to your testing issues.
                </p>
                <br />
                <p>
                  The QA function has unique challenges in every enterprise, be
                  it a small enterprise that is just at the beginning of setting
                  up, a QA team or a multi-billion dollar giant with matured QA
                  processes. The right QA strategy can help you improve the
                  overall product quality and reduce release cycle time enabling
                  faster time-to-market and controlling the rising QA costs.
                </p>
                <br />
                <p>
                  Qualitek Test advisory Services provide you with the
                  specialist advice to achieve these objectives and help you
                  move to the next maturity level in QA. We deliver custom
                  solutions that address your current business needs and align
                  with your future aspirations. Using the TMMI Based Assessment,
                  Qualitek will assess your current test organization and map it
                  to the TMMI model. Qualitek will help clients test
                  organization for TMMI certification by identifying gaps and
                  areas of improvement. Based on that, the roadmap will be
                  defined for prioritized items to be implemented.
                </p>
              </div>
            </div>
          </div>

          <div className="service-content-box">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="service-content-desc" style={{ width: "100%" }}>
                <div
                  className="service-content-right-img"
                  style={{
                    position: "relative",
                    width: "80%",
                    height: "auto",
                  }}
                >
                  <img
                    src={require("../../Images/test-advisory-img-1.jpg")}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="service-header-box">
            <h2>Process for Test Advisory</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="service-content-desc" style={{ width: "100%" }}>
              <div className="service-content-left-desc">
                <p>
                  While the specifics of our test advisory services vary based
                  on your company and your requirements, we advise beginning
                  with a Test Assessment. A thorough review will help you
                  pinpoint the advantages and disadvantages of your existing
                  quality assurance (QA) strategy as well as the areas where we
                  think we can have the biggest influence on your company.
                </p>
                <p>
                  We define the goals for the Test Advisory exercise upfront and
                  then perform a holistic assessment across various dimensions
                  of your QA enterprise, i.e. Processes, People and Tools &
                  Technology.
                </p>
                <br />
                <p>
                  We leverage our extensive QA experience and in-house QA
                  accelerators, along with industry best practices and baselines
                  to come up with the best possible solutions. We assess testing
                  processes, enterprises & tools against best practices,
                  identify gaps in their ability to support business growth,
                  identify improvement areas and come up with recommendations
                  for the enterprise-wide testing processes. We help you develop
                  a roadmap of initiatives that will help align QA strategy with
                  the enterprise goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default TestAdvisory;
