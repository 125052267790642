import "../../App.css";

import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Footer1 from "../Footer1";
import "../AllTabs/DevopsServices";
import "../AllTabs/Homepage.css";
import "./TestAutomation.css";
import { Link } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function TestAutomation() {
  const footerRef = useRef(null);
  const [contentView, setContentView] = useState(["webview"]);
  const [width, height] = useWindowSize();

  const scrolltoFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (width < 768) {
      setContentView("mobileview");
    } else {
      setContentView("webview");
    }
  }, [width]);

  return (
    <div className="testing-service-container">
      <div className="banner-box-container-1">
        <div className="banner-box-container-2">
          <h2>
            Utilize test automation and CI/CD expertise to achieve "Quality At
            Speed"
          </h2>

          <h2 className="banner-box-container-2-h2">
            Develop a test automation solution that is aligned with DevOps using
            ready accelerators and the most recent best practises
          </h2>

          <div
            className="banner-box-btn"
            onClick={() => {
              scrolltoFooter();
            }}
          >
            <Link to={"/contact"} className="banner-box-btn-link">
              <h2>Talk To Us</h2>
            </Link>
          </div>
        </div>
      </div>

      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Automation Testing Services</h2>
          </div>

          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-img">
                <img src={require("../../Images/test-automation-img-1.png")} />
              </div>
            ) : null}
            <div className="service-content-desc">
              <div className="service-content-left-desc">
                <p>
                  Long-term cost benefits are achieved through test automation
                  by speeding up the time to market and minimising the amount of
                  time spent on regression testing. To ensure the best return on
                  investment from your automation activities, you must have a
                  defined automation plan and roadmap. To assure high
                  reusability, ease of maintenance, and lower upfront costs in
                  the face of different application design, multiple
                  environments, third-party connections, and multiple user
                  devices, a standardised and uniform automation approach is
                  required.
                </p>
                <br />
                <p>
                  QualiTek is one of the top automation testing businesses,
                  generating the value you've always wanted through test
                  automation. QualiTek has experience counselling clients on
                  test automation and architecting their automation journey.
                </p>
                <br />
                <p>
                  By creating an automation plan that is in line with corporate
                  objectives, our test automation solutions assist your business
                  objectives. To offer an optimum tool inventory that fits your
                  needs and budget, we perform a thorough tool appraisal
                  exercise.
                </p>
                <br />
                <p>
                  The boundaries between the development and operations teams in
                  the software industry are dissolving as a result of the growth
                  of DevOps. Organizations must adapt to DevOps in order to move
                  more quickly than ever before. This requires a mix of a
                  culture transformation and the adoption of the appropriate
                  tools, technologies, and best practises. With our wonderful
                  Devops Services, such as, QualiTek can guide you through this
                  path to optimise your procedures, equipment, and personnel.
                  Infrastructure as Code advisory and implementation services,
                  DevOps advising services, and CI/CD implementation services.
                </p>
                <br />
                <p>
                  All applications under test (AUTs) are made available for
                  development and testing thanks to QualiTek's Test Automation
                  Framework, which tests the behaviour and emulation of numerous
                  API & Cloud-based apps, SOAs, and microservices. Our Service
                  Virtualization practise gives businesses the ability to simply
                  manage test environments and simulate them quickly, allowing
                  them to provide high-quality software to the market more
                  quickly and at a considerably lower cost.
                </p>
              </div>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-img">
                <img src={require("../../Images/test-automation-img-1.png")} />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Test Automation Approach</h2>
        </div>
        <div className="service-content-type-1">
          <div className="service-content-left-img">
            <img src={require("../../Images/test-automation-img-5.png")} />
          </div>
          <div className="service-content-right-ul">
            <h2 className="service-ul-header-h2">
              Our balanced Test&nbsp;Automation approach uses:
            </h2>
            <ul className="service-differtiater-list-body">
              <li className="service-differtiater-list-item">
                Evaluation of your application landscape and automation
                requirements.
              </li>
              <li className="service-differtiater-list-item">
                Assessment of your existing automation solution. (if any)
              </li>
              <li className="service-differtiater-list-item">
                Evaluation of your current automation system. (if any)
              </li>
              <li className="service-differtiater-list-item">
                The choice of a tool, the Proof of Concept, and its use.
              </li>
              <li className="service-differtiater-list-item">
                Automated creation and execution of test scripts.
              </li>
              <li className="service-differtiater-list-item">
                Development of configurable execution options for regression
                test suites.
              </li>
              <li className="service-differtiater-list-item">
                Training and delivery of the automated test pack to the client
                for ongoing support.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>Test Automation Benefits</h2>
          </div>

          <div
            className="service-content-right-img"
            style={{
              position: "relative",
              width: "80%",
              height: "auto",
              padding: 0,
            }}
          >
            <img
              src={require("../../Images/test-automation-img-4.png")}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div
        className="service-content-box"
        style={{ backgroundColor: "rgb(238, 237, 236)" }}
      >
        <div className="service-header-box">
          <h2>Test Automation Framework</h2>
        </div>

        <div className="service-content-type-1">
          <div className="service-content-left-img">
            <img
              src={require("../../Images/test-automation-img-3.png")}
              style={{ width: "100%" }}
            />
          </div>
          <div className="service-content-desc">
            <div className="service-content-right-desc">
              <p>
                Our automation framework includes best-in-class functionality
                for test automation right out of the box. extensive custom
                reporting, integrations with outside parties, customisable
                execution options, etc. By merging numerous automated tests and
                making those test suites available for execution, the framework
                assists in configuring/creating test suites.
              </p>
              <br />
              <p>
                It incorporates features and best practises from the industry
                and can significantly lessen the work required to start
                automation. As one of the finest automation testing
                organisations, we can enable test automation with a scripting
                language your team is familiar with and have specialised teams
                with core experience on all market-leading tools including
                Selenium, HP UFT, Coded UI, TestComplete, Ranorex, Appium, etc.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="service-content-container">
        <div className="service-content-box">
          <div className="service-header-box">
            <h2>QualiTek Differentiators</h2>
          </div>
          <div className="service-content-type-1">
            {contentView == "mobileview" ? (
              <div className="service-content-right-ul-img">
                <img src={require("../../Images/test-automation-img-2.png")} />
              </div>
            ) : null}
            <div className="service-content-right-ul" style={{ padding: 0 }}>
              <ul className="service-differtiater-list-body">
                <li className="service-differtiater-list-item">
                  Starting out right with a thorough automation assessment to
                  develop a transparent automation strategy and roadmap
                </li>
                <li className="service-differtiater-list-item">
                  Industry-leading automation frameworks that have been
                  customised to meet your needs are ready for deployment.
                </li>
                <li className="service-differtiater-list-item">
                  Improve reusability and maintenance efficiency to reduce
                  continuing expenditures.
                </li>
                <li className="service-differtiater-list-item">
                  Assist in the integration of tools for test management, defect
                  management, and continuous integration to provide full
                  traceability and lifecycle automation.
                </li>
                <li className="service-differtiater-list-item">
                  Proven selenium automated testing process
                </li>
                <li className="service-differtiater-list-item">
                  Proficiency in the most popular open source and paid
                  automation technologies for online, desktop, and mobile apps.
                </li>
                <li className="service-differtiater-list-item">
                  Flexibility in performing tests across numerous testing
                  environments at various levels, such as application level,
                  suite level, test case level, etc.
                </li>
                <li className="service-differtiater-list-item">
                  Delivered to all stakeholders are customised test execution
                  reports with a sophisticated UI.
                </li>
                <li className="service-differtiater-list-item">
                  Knowledge of the top tools for managing the application
                  lifecycle, tests, defects, test data, etc.
                </li>
                <li className="service-differtiater-list-item">
                  Structured project communications, such as daily, weekly, and
                  monthly progress reports that include thorough analysis and
                  status updates on test execution.
                </li>
              </ul>
            </div>
            {contentView == "webview" ? (
              <div className="service-content-right-ul-img">
                <img src={require("../../Images/test-automation-img-2.png")} />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="end-banner" ref={footerRef} key="footerId">
        <Footer1 />
      </div>
    </div>
  );
}

export default TestAutomation;
